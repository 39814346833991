import { FC, useMemo } from "react";
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BasicModal from "./BasicModal";
import { Fade } from "@mui/material";
import { CloseModalButton, ModalButton } from "components/Buttons";
import { Select, TextInput } from "components/Inputs";
import { SelectItemProps } from "components/Inputs/types";
import { AppText, Preloader } from "components";
import countries from "helpers/countries";
import { streetInputValidator, nameInputValidator } from "helpers/regex";
import { ModalHeading, Form, InputContainer } from "./styled";
import { createDeliveryAddress } from "api/creditco";
import { GlobalStore } from "stores";
import type { CreateDeliveryAddressesReq } from "api/types/creditco";
import type { DeliveryAddress } from "helpers/types";

const requiredField = "REQUIRED_FIELD";

interface InitialErrors {
  country?: string;
  city?: string;
  address?: string;
  postCode?: string;
}
interface DepositModalProps {
  isOpen: boolean;
  onClose: () => void;
  setNewDeliveryAddresses: (address: DeliveryAddress) => void;
  setNewAddress: (id: number) => void;
}

const AddDeliveryAddressModal: FC<DepositModalProps> = ({
  isOpen,
  onClose,
  setNewDeliveryAddresses,
  setNewAddress,
}) => {
  const { t } = useTranslation();
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      country: "",
      city: "",
      address: "",
      postCode: "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const personalData: CreateDeliveryAddressesReq = {
        country: values.country,
        city: values.city.replace(/\s+/g, " "),
        address: values.address.replace(/\s+/g, " "),
        postCode: values.postCode.replace(/\s+/g, " "),
      };

      createDeliveryAddress(personalData)
        .then(({ data }) => {
          setNewDeliveryAddresses(data);
          return data;
        })
        .then((newAddresses) => {
          const newAddress = newAddresses?.id;
          if (newAddress) {
            setNewAddress(newAddress);
          }
        })
        .then(() => onClose())
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setSubmitting(false));
    },
    validateOnChange: false,
    validateOnBlur: true,
    validate: (values) => {
      const errors: InitialErrors = {};
      if (!values.country) {
        errors.country = requiredField;
      }
      if (!values.city) {
        errors.city = requiredField;
      }
      if (!values.address) {
        errors.address = requiredField;
      }
      if (!values.postCode) {
        errors.postCode = requiredField;
      }

      if (values.city && !nameInputValidator.test(values.city)) {
        errors.city = `Only latin characters allowed (plus space, ' and - symbols)`;
      }
      if (values.address && !streetInputValidator.test(values.address)) {
        errors.address = `Only latin characters allowed (plus space, ', /, ",", and - symbols)`;
      }

      return errors;
    },
  });

  const closeModal = () => {
    if (isSubmitting) {
      return;
    }
    onClose();
  };

  const countriesOptions = useMemo<Array<SelectItemProps>>(() => {
    return countries.map(({ name }) => ({
      value: name,
      label: name,
    }));
  }, []);

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={resetForm}
      onClose={closeModal}
      title={t("ADD_DELIVERY_ADDRESS")}
    >
      <Fade in timeout={500}>
        <Form onSubmit={handleSubmit}>
          {isSubmitting && <Preloader isStatic />}
          <CloseModalButton onClose={closeModal} />
          <ModalHeading>{t("ADD_DELIVERY_ADDRESS")}</ModalHeading>

          <InputContainer>
            <AppText>{t("COUNTRY")}*</AppText>
            <Select
              name="country"
              placeholder={t("CHOOSE_COUNTRY")}
              variants={countriesOptions}
              value={values.country}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.country && Boolean(errors.country)}
              helperText={
                touched.country && !values.country && t(errors.country || "")
              }
            />
          </InputContainer>
          <InputContainer>
            <AppText>{t("CITY")}*</AppText>
            <TextInput
              name="city"
              placeholder={t("ENTER_CITY")}
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.city && Boolean(errors.city)}
              helperText={touched.city && t(errors.city || "")}
            />
          </InputContainer>
          <InputContainer>
            <AppText>{t("ADDRESS")}*</AppText>
            <TextInput
              name="address"
              placeholder={t("ENTER_ADDRESS")}
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.address && Boolean(errors.address)}
              helperText={touched.address && t(errors.address || "")}
            />
          </InputContainer>
          <InputContainer>
            <AppText>{t("POST_CODE")}*</AppText>
            <TextInput
              name="postCode"
              placeholder={t("ENTER_POST_CODE")}
              value={values.postCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.postCode && Boolean(errors.postCode)}
              helperText={touched.postCode && t(errors.postCode || "")}
            />
          </InputContainer>

          <ModalButton type="submit" disabled={isSubmitting}>
            {t("ADD_DELIVERY_ADDRESS")}
          </ModalButton>
        </Form>
      </Fade>
    </BasicModal>
  );
};

export default observer(AddDeliveryAddressModal);
