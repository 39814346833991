import {
  FC,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useMemo,
  useCallback,
  FormEvent,
} from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import {
  CurrenciesStore,
  UserStore,
  // GlobalStore,
  ByBitStore,
} from "stores";
import {
  ModalButton,
  CloseModalButton,
  CurrencyButton,
  // MainButton,
} from "components/Buttons";
import {
  // TwoFaModal,
  ModalWarningInfo,
} from "components/Modals";
import { SelectItemProps } from "components/Inputs/types";
import {
  CurrencyInput,
  RangeInput,
  Select,
  StyledTextInput,
  // TextInput,
} from "components/Inputs";
import WithdrawInfoRows from "./WithdrawInfoRows";
import {
  AppText,
  Checkbox,
  // Warning,
  VerificationWarning,
} from "components";
import ByBitCoinSelect from "../ByBitCoinSelect";
import {
  normolizeCurrenciesDecimals,
  toLocaleStringWithCurrency,
  getCurrenciesPriority,
} from "helpers/funcs";
// import { add2FAVerification } from "api/user";
import {
  ModalContent,
  ModalHeading,
  InnerContentForm,
  MainContainer,
  TransactionGroup,
  InfoLine,
  ModalError,
  ModalErrorStatus,
  SemiContainer,
  // InputContainer,
} from "../styled";
import type {
  AvailableTradeOptions,
  CurrencyInterface,
  ByBitDepositWithdrawAsset,
  ByBitDepositWithdrawAssetsFull,
} from "helpers/types";

import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";
// import { ReactComponent as LockIcon } from "assets/icons/lock-unlocked.svg";

const exceedAmount = "ERROR_NO_AMOUNT";
const lowerThanMinAmount = "ERROR_MIN_AMOUNT";

interface MainWithdrawStepProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setSkipConfirm: Dispatch<SetStateAction<boolean>>;
  skipConfirm: boolean;
  onClose: () => void;
  currencyValue: string;
  currency: AvailableTradeOptions | null;
  setCurrency: Dispatch<SetStateAction<AvailableTradeOptions | null>>;
  setCurrencyValue: Dispatch<SetStateAction<string>>;
  fees: number;
  currencies: CurrencyInterface[] | null;
  networkOptions?: Array<SelectItemProps>;
  chosenNetwork: string;
  setChosenNetwork: Dispatch<SetStateAction<string>>;
  withdrawAddress: string;
  setWithdrawAddress: Dispatch<SetStateAction<string>>;
  // setIsLoading: Dispatch<SetStateAction<boolean>>;
  // twoFACode: string;
  // setTwoFACode: Dispatch<SetStateAction<string>>;
  withdrawMemo: string;
  setWithdrawMemo: Dispatch<SetStateAction<string>>;
  hasMemo: boolean;
  isLoading: boolean;
  networkInfo: ByBitDepositWithdrawAsset | null;
}

const MainWithdrawStep: FC<MainWithdrawStepProps> = ({
  onSubmit,
  onClose,
  currencyValue,
  currency,
  setCurrencyValue,
  skipConfirm,
  setSkipConfirm,
  fees,
  networkOptions,
  chosenNetwork,
  setChosenNetwork,
  setCurrency,
  withdrawAddress,
  setWithdrawAddress,
  // setIsLoading,
  // twoFACode,
  // setTwoFACode,
  withdrawMemo,
  setWithdrawMemo,
  hasMemo,
  isLoading,
  networkInfo,
}) => {
  const { t } = useTranslation();
  const { balancesList, availableWithdraws } = ByBitStore;
  const { currencies } = CurrenciesStore;
  const {
    user: { is2FAEnabled, level },
  } = UserStore;
  const [hasError, setHasError] = useState<string | null>(null);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isWarnConfirmed, setIsWarnConfirmed] = useState<boolean>(false);
  // const [twoFaUrl, setTwoFaUrl] = useState<string>("");
  // const [isTwoFaOpen, setIsTwoFaOpen] = useState<boolean>(false);

  const closeSelect = () => setIsSelectOpen(false);
  const openSelect = () => setIsSelectOpen(true);

  const currenciesOptions = useMemo<AvailableTradeOptions[] | null>(() => {
    if (balancesList && availableWithdraws && currencies) {
      const currenciesPriority = getCurrenciesPriority(
        currencies,
        balancesList
      );
      const withdrawAvailableCoins = availableWithdraws.reduce(
        (acc: AvailableTradeOptions[], el: ByBitDepositWithdrawAssetsFull) => {
          if (!acc.includes(el.coin)) {
            acc.push(el.coin);
          }
          return acc;
        },
        []
      );
      return balancesList
        .filter(({ coin }) => withdrawAvailableCoins?.includes(coin))
        .map(({ coin }) => coin)
        .sort((a, b) => currenciesPriority[b] - currenciesPriority[a]);
    }
    return null;
  }, [balancesList, availableWithdraws, currencies]);

  const currencyBalance = useMemo<number>(() => {
    const leftBalance = balancesList?.find(
      ({ coin }) => coin === currency
    )?.walletBalance;
    return !currency || !leftBalance
      ? 0
      : Number(normolizeCurrenciesDecimals(leftBalance, currency, currencies));
  }, [balancesList, currency, currencies]);

  const fullCurrencyBalance = useMemo<number>(() => {
    const leftBalance = balancesList?.find(
      ({ coin }) => currency === coin
    )?.walletBalance;

    return !currency || !leftBalance ? 0 : Number(leftBalance);
  }, [balancesList, currency]);

  const minWithdraw = useMemo<number>(
    () => (networkInfo ? Number(networkInfo.withdrawMin) : 0),
    [networkInfo]
  );

  const checkIfError = (value: string) => {
    if (hasError) {
      setHasError(null);
    }
    if (
      Number(value) > 0 &&
      (fees ? Number(value) + fees : Number(value)) > fullCurrencyBalance
    ) {
      setHasError(t(exceedAmount));
    }
    if (minWithdraw > 0 && value && Number(value) < minWithdraw) {
      setHasError(`${t(lowerThanMinAmount)} (${minWithdraw} ${currency})`);
    }
  };

  const onAssetChange = (option: AvailableTradeOptions) => {
    if (option === currency) {
      return;
    }
    setCurrency(option);
    setCurrencyValue("");
    setWithdrawAddress("");
    setWithdrawMemo("");
    if (availableWithdraws) {
      const network = availableWithdraws.find(
        (el) => el.coin === option
      )?.chains;
      setChosenNetwork(
        network && network.length === 1 ? network[0].chain || "" : ""
      );
      return;
    }

    setChosenNetwork("");
  };

  const handleChangeValue = (value: string) => {
    setCurrencyValue(value);
    checkIfError(value);
  };

  const onSelectNetwork = useCallback(
    (networkValue: string) => {
      if (currencies && networkValue !== chosenNetwork) {
        setWithdrawAddress("");
        setWithdrawMemo("");
        setChosenNetwork(networkValue);
      }
    },
    [
      currencies,
      chosenNetwork,
      setChosenNetwork,
      setWithdrawAddress,
      setWithdrawMemo,
    ]
  );

  useEffect(() => {
    handleChangeValue(currencyValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenNetwork, currency]);

  // const change2Fa = useCallback(() => {
  //   if (is2FAEnabled) {
  //     setIsTwoFaOpen(true);
  //     return;
  //   }
  //   setIsLoading(true);
  //   add2FAVerification()
  //     .then(({ data }) => {
  //       setTwoFaUrl(data.url);
  //       setIsTwoFaOpen(true);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message) {
  //         GlobalStore.setError(err.response.data.message);
  //       }
  //     })
  //     .finally(() => setIsLoading(false));
  // }, [is2FAEnabled, setIsLoading]);

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <CloseModalButton onClose={onClose} />
        <ModalHeading>{t("WITHDRAW")}</ModalHeading>
        <InnerContentForm onSubmit={(e) => onSubmit(e)}>
          <MainContainer>
            <TransactionGroup>
              <AppText color={colors.gray_500}>{t("CHOOSE_METHOD")}</AppText>
              <CurrencyButton
                value={currency || undefined}
                icon={currency || undefined}
                onClick={openSelect}
              />
            </TransactionGroup>

            {networkOptions && (
              <TransactionGroup>
                <Select
                  variants={networkOptions}
                  value={chosenNetwork}
                  setValue={onSelectNetwork}
                  label={t("SELECT_NETWORK")}
                />
              </TransactionGroup>
            )}
          </MainContainer>

          {level === "BASIC" || level === "JUNIOR" ? (
            <VerificationWarning text={t("WITHDRAW_VERIFY_REQUIRED")} />
          ) : (
            <>
              <>
                {chosenNetwork && networkInfo && (
                  <ModalWarningInfo
                    text={`${t("WITHDRAW_WARN_1")} ${currency} ${t("TO")} ${
                      networkInfo?.chainType
                    } ${t("WITHDRAW_WARN_2")}`}
                    subText={t("WITHDRAW_WARN_3")}
                  />
                )}

                <MainContainer $customMargin={12}>
                  <StyledTextInput
                    value={withdrawAddress}
                    setValue={(value) => setWithdrawAddress(value)}
                    leftAdornment={<AppText>Address</AppText>}
                  />

                  {hasMemo && (
                    <StyledTextInput
                      value={withdrawMemo}
                      setValue={(value) => setWithdrawMemo(value)}
                      leftAdornment={<AppText>Memo</AppText>}
                    />
                  )}

                  <TransactionGroup>
                    {minWithdraw !== 0 && (
                      <InfoLine>
                        <AppText color={colors.gray_900} fontSize={12}>
                          Min {minWithdraw} {currency}
                        </AppText>
                      </InfoLine>
                    )}

                    <CurrencyInput
                      value={currencyValue}
                      setValue={(value) => handleChangeValue(value)}
                      currency={currency}
                      currencies={currencies}
                      onClick={openSelect}
                      placeholder={
                        minWithdraw !== 0 ? `min ${minWithdraw}` : "0"
                      }
                      noSelect
                    />

                    <InfoLine>
                      <WalletIcon />
                      <AppText
                        color={colors.gray_600}
                        fontWeight={400}
                        fontSize={12}
                      >
                        {t("BALANCE")} {currencyBalance} {currency}
                      </AppText>
                    </InfoLine>
                  </TransactionGroup>

                  <RangeInput
                    value={currencyValue}
                    setValue={handleChangeValue}
                    balance={currencyBalance}
                    mainCurrency={currency}
                    currencies={currencies}
                    fees={fees}
                  />
                </MainContainer>
              </>

              <SemiContainer>
                <WithdrawInfoRows
                  currencyValue={currencyValue}
                  currency={currency}
                  fees={fees}
                />

                <Checkbox
                  checked={skipConfirm}
                  onClick={() => setSkipConfirm(!skipConfirm)}
                  label={t("WITHOUT_CONFIMATION")}
                  variant="info"
                />

                <Checkbox
                  checked={isWarnConfirmed}
                  onClick={() => setIsWarnConfirmed(!isWarnConfirmed)}
                  label={t("PROCEDURE_WARN")}
                  variant="warn"
                />

                {/* {!is2FAEnabled && (
                  <Warning
                    text={t("TWO_FA_FOR_WITHDRAW")}
                    extraNode={
                      <MainButton
                        maxWidth="14rem"
                        color="secondary"
                        onClick={() => change2Fa()}
                      >
                        <LockIcon />
                        {t("ENABLE")}
                      </MainButton>
                    }
                  />
                )} */}

                {hasError && (
                  <ModalError>
                    <ModalErrorStatus>
                      <AppText fontSize={12} color={colors.error_700}>
                        {t("WARNING")}
                      </AppText>
                    </ModalErrorStatus>
                    <AppText fontSize={12} color={colors.error_700}>
                      {hasError}
                    </AppText>
                  </ModalError>
                )}

                {/* {skipConfirm && (
                  <InputContainer $marginTop>
                    <AppText>{t("2FA Code")}</AppText>
                    <TextInput
                      placeholder={t("CODE")}
                      value={twoFACode}
                      onChange={({ target: { value } }) => setTwoFACode(value)}
                      leftIcon={<LockIcon />}
                      autoComplete="off"
                      name="2fa-code"
                    />
                  </InputContainer>
                )} */}

                <ModalButton
                  disabled={
                    Number(currencyValue) <= 0 ||
                    !!hasError ||
                    !isWarnConfirmed ||
                    !chosenNetwork ||
                    !is2FAEnabled ||
                    isLoading ||
                    withdrawAddress.length < 4 ||
                    (hasMemo && withdrawMemo.length < 4)
                    // || (skipConfirm && !twoFACode)
                  }
                  type="submit"
                >
                  {t("WITHDRAW")}{" "}
                  {Number(currencyValue) > 0 &&
                    toLocaleStringWithCurrency(
                      Number(currencyValue),
                      currency || "USDT",
                      currencies
                    )}{" "}
                  {Number(currencyValue) > 0 && fees
                    ? `+ ${fees} ${currency || "USDT"} Fees`
                    : ""}
                </ModalButton>
              </SemiContainer>
            </>
          )}
        </InnerContentForm>

        <ByBitCoinSelect
          onClose={closeSelect}
          isOpen={isSelectOpen}
          options={currenciesOptions}
          setValue={onAssetChange}
          isDeposit
        />
        {/* <TwoFaModal
          isOpen={isTwoFaOpen}
          url={twoFaUrl}
          onClose={() => setIsTwoFaOpen(false)}
        /> */}
      </ModalContent>
    </Fade>
  );
};

export default observer(MainWithdrawStep);
