import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors, currencyIcons } from "helpers/consts";
import { ModalButton, CloseModalButton } from "components/Buttons";
import { AppText } from "components";
import StakeInfoRows from "./StakeInfoRows";
import { normolizeDecimalsWithNoCode } from "helpers/funcs";
import {
  StatusContent,
  StatusDecorative,
  StatusIconContainer,
  StatusInfo,
  InfoColumn,
  StatusInfoLine,
  ModalContent,
  ConfirmInfo,
  ModalHeading,
  InnerContent,
  ConfirmBlock,
  SuccessContent,
} from "../styled";
import type {
  StakeStrategyInterface,
  StakeType,
  CurrencyInterface,
  StakeInterface,
} from "helpers/types";

import stakeImg from "assets/images/stake_success.jpg";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";

interface SuccessStakeStepProps {
  onClose: () => void;
  stakeValue?: string;
  strategy?: StakeStrategyInterface | null;
  stakeType: StakeType;
  currentUnstake?: StakeInterface | null;
  currencies: CurrencyInterface[] | null;
  cost: number;
}

const SuccessStakeStep: FC<SuccessStakeStepProps> = ({
  onClose,
  stakeValue,
  strategy,
  stakeType,
  currencies,
  currentUnstake,
  cost,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <SuccessContent>
        {stakeType === "stake" ? (
          <StatusContent>
            <StatusDecorative>
              <img src={stakeImg} alt="decorative" />
              <StatusIconContainer>
                <CheckIcon />
              </StatusIconContainer>
            </StatusDecorative>
            <StatusInfo>
              <InfoColumn>
                <AppText
                  fontSize={20}
                  fontWeight={600}
                  color={colors.gray_600}
                  lineHeight={30}
                >
                  {strategy!.currencyName}
                </AppText>
                <AppText fontSize={16} fontWeight={400} color={colors.gray_700}>
                  {t("STAKE")} {t("CONFIRMED")}
                </AppText>
                <StatusInfoLine>
                  {currencyIcons[strategy!.currencyName]
                    ? currencyIcons[strategy!.currencyName]
                    : currencyIcons.not_found}
                  <AppText
                    fontSize={20}
                    fontWeight={600}
                    color={colors.gray_700}
                    lineHeight={30}
                  >
                    {normolizeDecimalsWithNoCode(
                      Number(stakeValue),
                      strategy!.currencyName,
                      currencies
                    )}
                  </AppText>
                  <AppText
                    isSpan
                    fontSize={20}
                    fontWeight={600}
                    color={colors.gray_400}
                    lineHeight={30}
                  >
                    {strategy!.currencyName}
                  </AppText>
                </StatusInfoLine>
              </InfoColumn>

              {stakeValue && strategy && (
                <StakeInfoRows
                  stakeValue={stakeValue}
                  strategy={strategy}
                  currencies={currencies}
                  cost={cost}
                />
              )}

              <ModalButton onClick={onClose}>{t("DONE")}</ModalButton>
            </StatusInfo>
          </StatusContent>
        ) : (
          <ModalContent>
            <CloseModalButton onClose={onClose} />
            <ModalHeading>{t("UNSTAKE")}</ModalHeading>
            <InnerContent>
              <ConfirmBlock>
                <StatusIconContainer $isStatic>
                  <CheckIcon />
                </StatusIconContainer>
                <AppText
                  fontSize={20}
                  fontWeight={600}
                  color={colors.gray_700}
                  lineHeight={30}
                >
                  {normolizeDecimalsWithNoCode(
                    Number(currentUnstake!.amount),
                    currentUnstake!.currencyName,
                    currencies
                  )}
                  <AppText
                    isSpan
                    fontSize={20}
                    fontWeight={600}
                    color={colors.gray_400}
                    lineHeight={30}
                  >
                    {" "}
                    {currentUnstake!.currencyName}
                  </AppText>
                </AppText>
                <AppText fontSize={16} fontWeight={400} color={colors.gray_700}>
                  {t("UNSTAKE_INITIATED")}
                </AppText>
                <ConfirmInfo>
                  <AppText fontWeight={400} color={colors.gray_700}>
                    {`${t("START_EARN_AGAIN_1")} ${
                      currentUnstake!.currencyName
                    } ${t("START_EARN_AGAIN_2")}`}
                  </AppText>
                </ConfirmInfo>
              </ConfirmBlock>
              <ModalButton onClick={onClose}>{t("DONE")}</ModalButton>
            </InnerContent>
          </ModalContent>
        )}
      </SuccessContent>
    </Fade>
  );
};

export default SuccessStakeStep;
