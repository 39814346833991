import { makeAutoObservable } from "mobx";
import { availableNoFntCurrencies } from "helpers/consts";
import type {
  WalletsListInterface,
  WalletsAddressInterface,
} from "helpers/types";
class Store {
  balance: number = 0;
  walletsList: WalletsListInterface[] | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setBalance = (value: number) => {
    this.balance = value;
  };

  setWalletsList = (wallets: WalletsListInterface[] | null) => {
    const filteredWallets =
      wallets?.filter(({ currencyName }) =>
        availableNoFntCurrencies.includes(currencyName)
      ) || null;
    this.walletsList = filteredWallets;
  };

  addWallet = (wallet: WalletsListInterface) => {
    if (!this.walletsList) {
      this.walletsList = [wallet];
      return;
    }
    this.walletsList = [...this.walletsList, wallet];
  };

  addWalletAddress = (address: WalletsAddressInterface, currency: string) => {
    if (!this.walletsList) {
      return;
    }
    const newWalletList = [...this.walletsList].reduce(
      (acc: WalletsListInterface[], el: WalletsListInterface) => {
        if (el.currencyName !== currency) {
          acc.push(el);
          return acc;
        }
        el.addresses.push(address);
        acc.push(el);
        return acc;
      },
      []
    );

    this.walletsList = newWalletList;
  };
}

const walletStore = new Store();
export default walletStore;
