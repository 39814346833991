import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  FormEvent,
} from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Preloader } from "components";
import BasicModal from "../BasicModal";
import MainWithdrawStep from "./MainWithdrawStep";
import ConfirmWithdrawStep from "./ConfirmWithdrawStep";
import SuccessWithdrawStep from "./SuccessWithdrawStep";
import { SelectItemProps } from "components/Inputs/types";
import { CurrenciesStore, GlobalStore, ByBitStore } from "stores";
import { byBitWithdraw } from "api/byBit";
import type { ByBitWithdrawReq } from "api/types/bybit";
import type {
  ModalStepsType,
  AvailableTradeOptions,
  ByBitDepositWithdrawAsset,
} from "helpers/types";

const hasMemo = false;

interface WithdrawModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialVariant: AvailableTradeOptions;
}
const WithdrawModal: FC<WithdrawModalProps> = ({
  isOpen,
  onClose,
  initialVariant,
}) => {
  const { t } = useTranslation();
  const { currencies } = CurrenciesStore;
  const { availableWithdraws } = ByBitStore;
  const [step, setStep] = useState<ModalStepsType>("main");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [skipConfirm, setSkipConfirm] = useState<boolean>(false);
  const [currencyValue, setCurrencyValue] = useState<string>("");
  const [chosenNetwork, setChosenNetwork] = useState<string>("");
  const [withdrawAddress, setWithdrawAddress] = useState<string>("");
  const [withdrawMemo, setWithdrawMemo] = useState<string>("");
  // const [twoFACode, setTwoFACode] = useState<string>("");
  const [chosenAsset, setChosenAsset] = useState<AvailableTradeOptions | null>(
    null
  );

  // const hasMemo = useMemo<boolean>(() => {
  //   if (chosenNetwork && paymentType?.useMemo) {
  //     return true;
  //   }
  //   return false;
  // }, [chosenNetwork, paymentType]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (step === "main" && !skipConfirm) {
      setStep("confirm");
      return;
    }

    const withdrawData: ByBitWithdrawReq = {
      coin: chosenAsset!,
      chain: chosenNetwork,
      address: withdrawAddress.replaceAll(" ", ""),
      amount: currencyValue,
    };

    if (hasMemo && withdrawMemo) {
      withdrawData.tag = withdrawMemo;
    }

    setIsLoading(true);

    byBitWithdraw(withdrawData)
      .then(() => setStep("success"))
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isOpen) {
      setChosenAsset(initialVariant);
    }
  }, [initialVariant, isOpen]);

  const closeModal = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const resetState = useCallback(() => {
    setStep("main");
    setCurrencyValue("");
    setChosenNetwork("");
    setWithdrawAddress("");
    setWithdrawMemo("");
    // setTwoFACode("");
    setChosenAsset(null);
  }, []);

  const networkInfo = useMemo<ByBitDepositWithdrawAsset | null>(() => {
    if (chosenNetwork && chosenAsset && availableWithdraws) {
      return (
        availableWithdraws
          .find(({ coin }) => coin === chosenAsset)
          ?.chains.find(({ chain }) => chain === chosenNetwork) || null
      );
    }
    return null;
  }, [chosenNetwork, chosenAsset, availableWithdraws]);

  const fixedFees = useMemo<number>(
    () => (networkInfo ? Number(networkInfo.withdrawFee) : 0),
    [networkInfo]
  );

  const networkOptions = useMemo<Array<SelectItemProps> | undefined>(() => {
    if (availableWithdraws && chosenAsset) {
      return availableWithdraws
        .find(({ coin }) => coin === chosenAsset)
        ?.chains.map((el) => ({
          value: el.chain,
          label: el.chainType,
        }));
    }
    return undefined;
  }, [availableWithdraws, chosenAsset]);

  useEffect(() => {
    if (isOpen && !chosenNetwork && networkOptions?.length === 1) {
      setChosenNetwork(String(networkOptions[0].value));
    }
  }, [networkOptions, chosenNetwork, isOpen]);

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={resetState}
      onClose={closeModal}
      title={t("WITHDRAW")}
    >
      {isLoading && <Preloader isStatic />}

      {step === "main" && (
        <MainWithdrawStep
          onSubmit={onSubmit}
          skipConfirm={skipConfirm}
          setSkipConfirm={setSkipConfirm}
          currencyValue={currencyValue}
          currency={chosenAsset}
          setCurrencyValue={setCurrencyValue}
          setCurrency={setChosenAsset}
          onClose={closeModal}
          fees={fixedFees}
          currencies={currencies}
          networkOptions={networkOptions}
          chosenNetwork={chosenNetwork}
          setChosenNetwork={setChosenNetwork}
          setWithdrawAddress={setWithdrawAddress}
          withdrawAddress={withdrawAddress}
          // setIsLoading={setIsLoading}
          // setTwoFACode={setTwoFACode}
          withdrawMemo={withdrawMemo}
          hasMemo={hasMemo}
          setWithdrawMemo={setWithdrawMemo}
          // twoFACode={twoFACode}
          isLoading={isLoading}
          networkInfo={networkInfo}
        />
      )}
      {step === "confirm" && (
        <ConfirmWithdrawStep
          onSubmit={onSubmit}
          skipConfirm={skipConfirm}
          setSkipConfirm={setSkipConfirm}
          currencyValue={currencyValue}
          currency={chosenAsset}
          onBack={() => setStep("main")}
          onClose={closeModal}
          fees={fixedFees}
          currencies={currencies}
          // setTwoFACode={setTwoFACode}
          // twoFACode={twoFACode}
          isLoading={isLoading}
        />
      )}
      {step === "success" && (
        <SuccessWithdrawStep
          currencyValue={currencyValue}
          currency={chosenAsset}
          onClose={closeModal}
          fees={fixedFees}
          currencies={currencies}
        />
      )}
    </BasicModal>
  );
};

export default observer(WithdrawModal);
