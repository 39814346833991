import { FC, useCallback, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BasicModal from "./BasicModal";
import { Fade } from "@mui/material";
import { CloseModalButton, ModalButton } from "components/Buttons";
import { Select, TextInput, DateInput, PhoneInput } from "components/Inputs";
import { SelectItemProps } from "components/Inputs/types";
import { AppText, Preloader } from "components";
import countries from "helpers/countries";
import { getUTCTimezoneDate } from "helpers/funcs";
import { streetInputValidator, nameInputValidator } from "helpers/regex";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
  postCodeMask,
} from "helpers/postCodeHelpers";
import { ModalHeading, Form, InputContainer } from "./styled";
import { updateJuniorPersonalData, getIsLogged } from "api/user";
import { GlobalStore } from "stores";
import moment from "moment";
import type { JuniorPersonalData } from "api/types/user";

const requiredField = "REQUIRED_FIELD";
const invalidPhone = "INVALID_PHONE_NUMBER";
const defaultCountryPref = "United Kingdom";

interface InitialErrors {
  firstName?: string;
  lastName?: string;
  country?: string;
  city?: string;
  street?: string;
  postCode?: string;
  residence?: string;
  phoneNumber?: string;
  dateOfBirth?: string;
}
interface DepositModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const VerificationModal: FC<DepositModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldTouched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      country: "",
      city: "",
      street: "",
      postCode: "",
      residence: "",
      phoneNumber: "",
      phonePref: defaultCountryPref,
      dateOfBirth: moment(),
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const dialCode = countries.find(
        ({ name }) => name === values.phonePref
      )!.dial_code;

      const personalData: JuniorPersonalData = {
        firstName: values.firstName.replace(/\s+/g, " "),
        lastName: values.lastName.replace(/\s+/g, " "),
        country: values.country,
        city: values.city.replace(/\s+/g, " "),
        street: values.street.replace(/\s+/g, " "),
        postCode: values.postCode.replace(/\s+/g, " "),
        residence: values.residence,
        phoneNumber: `${dialCode} ${values.phoneNumber.replaceAll(" ", "")}`,
        dateOfBirth: getUTCTimezoneDate(values.dateOfBirth),
      };

      updateJuniorPersonalData(personalData)
        .then(() => getIsLogged())
        .then(() => onClose())
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setSubmitting(false));
    },
    validateOnChange: true,
    validate: (values) => {
      const errors: InitialErrors = {};
      if (!values.firstName) {
        errors.firstName = requiredField;
      }
      if (!values.lastName) {
        errors.lastName = requiredField;
      }
      if (!values.country) {
        errors.country = requiredField;
      }
      if (!values.city) {
        errors.city = requiredField;
      }
      if (!values.street) {
        errors.street = requiredField;
      }
      if (!values.postCode) {
        errors.postCode = requiredField;
      }
      if (!values.residence) {
        errors.residence = requiredField;
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = requiredField;
      }
      if (!values.dateOfBirth) {
        errors.dateOfBirth = requiredField;
      }

      if (values.firstName && !nameInputValidator.test(values.firstName)) {
        errors.firstName = `Only latin characters allowed (plus space, ' and - symbols)`;
      }
      if (values.lastName && !nameInputValidator.test(values.lastName)) {
        errors.lastName = `Only latin characters allowed (plus space, ' and - symbols)`;
      }
      if (values.city && !nameInputValidator.test(values.city)) {
        errors.city = `Only latin characters allowed (plus space, ' and - symbols)`;
      }
      if (values.street && !streetInputValidator.test(values.street)) {
        errors.street = `Only latin characters allowed (plus space, ', /, ",", and - symbols)`;
      }
      if (
        values.phoneNumber &&
        (values.phoneNumber.length < 4 || values.phoneNumber.length > 14)
      ) {
        errors.phoneNumber = invalidPhone;
      }
      if (values.country && values.postCode) {
        const countryCode = countries.find(
          ({ name }) => name === values.country
        )!.code;

        const isInvalid = validatePostCode(countryCode, values.postCode);
        if (isInvalid) {
          errors.postCode = `Invalid Postal code format${
            postCodeMask[countryCode]
              ? ` (Valid: ${postCodeMask[countryCode]})`
              : ""
          }`;
        }
      }

      return errors;
    },
  });

  const validatePostCode = useCallback((countryCode: string, value: string) => {
    const isValidatebleCountryCode =
      postcodeValidatorExistsForCountry(countryCode);
    if (!isValidatebleCountryCode) {
      return false;
    }
    const isValidPostCode = postcodeValidator(value, countryCode);
    return !isValidPostCode;
  }, []);

  const handlePhonePrefChange: (val: string) => void = (value) => {
    setFieldValue("phonePref", value);
  };

  const closeModal = () => {
    if (isSubmitting) {
      return;
    }
    onClose();
  };

  const countriesOptions = useMemo<Array<SelectItemProps>>(() => {
    return countries.map(({ name }) => ({
      value: name,
      label: name,
    }));
  }, []);

  const chosenCountryCode = useMemo<string>(() => {
    if (values.country) {
      return countries.find(({ name }) => name === values.country)!.code;
    }
    return "";
  }, [values.country]);

  useEffect(() => {
    if (values.country && values.phonePref === defaultCountryPref) {
      const preferrableNum = countries.find(
        ({ name }) => name === values.country
      );
      if (preferrableNum) {
        setFieldValue("phonePref", preferrableNum.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.country]);

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={resetForm}
      onClose={closeModal}
      title="Personal Data"
    >
      <Fade in timeout={500}>
        <Form onSubmit={handleSubmit}>
          {isSubmitting && <Preloader isStatic />}
          <CloseModalButton onClose={closeModal} />
          <ModalHeading>{t("PERSONAL_DATA")}</ModalHeading>

          <InputContainer>
            <AppText>{t("FIRST_NAME")}*</AppText>
            <TextInput
              name="firstName"
              placeholder={t("ENTER_FIRST_NAME")}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && t(errors.firstName || "")}
            />
          </InputContainer>
          <InputContainer>
            <AppText>{t("LAST_NAME")}*</AppText>
            <TextInput
              name="lastName"
              placeholder={t("ENTER_LAST_NAME")}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && t(errors.lastName || "")}
            />
          </InputContainer>
          <InputContainer>
            <AppText>{t("DATE_OF_BIRTH")}*</AppText>
            <DateInput
              name="dateOfBirth"
              value={values.dateOfBirth}
              setValue={(value: any) => {
                setFieldValue("dateOfBirth", value);
              }}
              error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
              helperText={
                Boolean(errors.dateOfBirth) && t(String(errors.dateOfBirth))
              }
            />
          </InputContainer>

          <InputContainer>
            <AppText>{t("COUNTRY")}*</AppText>
            <Select
              name="country"
              placeholder={t("CHOOSE_COUNTRY")}
              variants={countriesOptions}
              value={values.country}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.country && Boolean(errors.country)}
              helperText={touched.country && t(errors.country || "")}
            />
          </InputContainer>
          <InputContainer>
            <AppText>{t("CITY")}*</AppText>
            <TextInput
              name="city"
              placeholder={t("ENTER_CITY")}
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.city && Boolean(errors.city)}
              helperText={touched.city && t(errors.city || "")}
            />
          </InputContainer>
          <InputContainer>
            <AppText>{t("STREET")}*</AppText>
            <TextInput
              name="street"
              placeholder={t("ENTER_STREET")}
              value={values.street}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.street && Boolean(errors.street)}
              helperText={touched.street && t(errors.street || "")}
            />
          </InputContainer>
          <InputContainer>
            <AppText>{t("POST_CODE")}*</AppText>
            <TextInput
              name="postCode"
              placeholder={
                postCodeMask[chosenCountryCode]
                  ? `Format: ${postCodeMask[chosenCountryCode]}`
                  : t("ENTER_POST_CODE")
              }
              value={values.postCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.postCode && Boolean(errors.postCode)}
              helperText={touched.postCode && t(errors.postCode || "")}
            />
          </InputContainer>

          <InputContainer>
            <AppText>{t("RESIDENCE")}*</AppText>
            <Select
              name="residence"
              placeholder={t("CHOOSE_RESIDENCE")}
              variants={countriesOptions}
              value={values.residence}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.residence && Boolean(errors.residence)}
              helperText={
                touched.residence &&
                !values.residence &&
                t(errors.residence || "")
              }
            />
          </InputContainer>

          <InputContainer style={{ marginBottom: "4rem" }}>
            <AppText>{t("PHONE_NUMBER")}*</AppText>
            <PhoneInput
              name="phoneNumber"
              placeholder={t("ENTER_PHONE_NUMBER")}
              value={values.phoneNumber}
              setValue={(value) => setFieldValue("phoneNumber", value)}
              onBlur={() => setFieldTouched("phoneNumber")}
              error={touched.phoneNumber && Boolean(errors.phoneNumber)}
              helperText={touched.phoneNumber && t(errors.phoneNumber || "")}
              phonePref={values.phonePref}
              setPref={handlePhonePrefChange}
            />
          </InputContainer>

          <ModalButton type="submit" disabled={isSubmitting}>
            {t("SEND")}
          </ModalButton>
        </Form>
      </Fade>
    </BasicModal>
  );
};

export default observer(VerificationModal);
