import axios from "axios";
import { StakesStore, GlobalStore } from "stores";
import type { StakeInterface } from "helpers/types";
import type {
  StakeStrategiesResponseInterface,
  StakeRequestInterface,
  UnstakeRequestInterface,
  StakesReqInterface,
  AllStakesResponseInterface,
} from "./types/stake";

export const getStakeStrategies = async () =>
  axios
    .get<StakeStrategiesResponseInterface>(`/staking/strategy/list`)
    .then(({ data }) => {
      StakesStore.setStakeStrategies(data.strategies);
      const strategiesCurr = data.strategies.map(
        ({ currencyName }) => currencyName
      );
      StakesStore.setStakeStrategiesCurrencies(strategiesCurr);
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        GlobalStore.setError(err.response.data.message);
      }
    });

export const stake = async (stakeData: StakeRequestInterface) =>
  axios.post<StakeInterface>("/staking/stake", stakeData);

export const unstake = async (unstakeData: UnstakeRequestInterface) =>
  axios.post<StakeInterface>("/staking/unstake", unstakeData);

export const getActiveStakes = async () =>
  axios
    .get<StakeInterface[]>("/staking/stake/active/list")
    .then(({ data }) => {
      StakesStore.setActiveStakes(data);
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        GlobalStore.setError(err.response.data.message);
      }
    });

export const getAllStakes = async (reqData: StakesReqInterface) =>
  axios.get<AllStakesResponseInterface>("/staking/stake/all/list", {
    params: { ...reqData },
  });
