import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicModal from "../BasicModal";
import { Preloader } from "components";
import { isValidPassword } from "helpers/funcs";
import { changePassword } from "api/user";
import { useFormik } from "formik";
import { GlobalStore } from "stores";
import MainChangePassStep from "./MainChangePassStep";
import SuccessChangePassStep from "./SuccessChangePassStep";
import type { ModalStepsType } from "helpers/types";
import type { ChangePasswordReq } from "api/types/user";

interface InitialErrors {
  oldPassword?: string;
  newPassword?: string;
  confirmPass?: string;
}

interface ChangePasswordModalProps {
  isOpen: boolean;
  is2FAEnabled: boolean;
  onClose: () => void;
}

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  isOpen,
  onClose,
  is2FAEnabled,
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState<ModalStepsType>("main");

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPass: "",
      oneTimePassword: "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const changePassData: ChangePasswordReq = {
        oldPassword: values.oldPassword.replace(/\s+/g, " "),
        newPassword: values.newPassword.replace(/\s+/g, " "),
      };

      if (is2FAEnabled) {
        changePassData.oneTimePassword = values.oneTimePassword.replace(
          /\s+/g,
          " "
        );
      }

      changePassword(changePassData)
        .then(() => setStep("success"))
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setSubmitting(false));
    },
    validate: (values) => {
      const errors: InitialErrors = {};
      if (!values.oldPassword) {
        errors.oldPassword = "PASSWORD_REQUIRED";
      }
      if (!values.newPassword) {
        errors.newPassword = "PASSWORD_REQUIRED";
      }
      if (!isValidPassword(values.newPassword)) {
        errors.newPassword = "PASSWORD_INVALID";
      }
      if (values.newPassword && values.confirmPass !== values.newPassword) {
        errors.confirmPass = "PASSWORD_DIFFERENT";
      }
      return errors;
    },
  });

  const resetState = useCallback(() => {
    resetForm();
    setStep("main");
  }, [resetForm]);

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={resetState}
      onClose={onClose}
      title={t("CHANGE_PASSWORD")}
    >
      {isSubmitting && <Preloader isStatic />}

      {step === "main" && (
        <MainChangePassStep
          values={values}
          touched={touched}
          errors={errors}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleBlur={handleBlur}
          onClose={onClose}
          is2FAEnabled={is2FAEnabled}
        />
      )}

      {step === "success" && <SuccessChangePassStep onClose={onClose} />}
    </BasicModal>
  );
};

export default ChangePasswordModal;
