import { Dispatch, FC, SetStateAction } from "react";
import { NavLink } from "react-router-dom";
import { Tab, Tabs, TabsProps, Badge } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colors, mainFont } from "helpers/consts";
import type { TabPanelProps } from "helpers/types";

export interface NavTabsInterface {
  label: string;
  href: string;
  badge?: number;
}
interface AppTabsProps {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  tabs?: string[];
  tabsLabel?: string;
  isHighlight?: boolean;
  noBorder?: boolean;
  isModal?: boolean;
  noMargin?: boolean;
  isScrollable?: boolean;
  isNavigation?: boolean;
  navTabs?: NavTabsInterface[];
  fontSize?: string;
  isThin?: boolean;
}

const AppTabs: FC<AppTabsProps> = ({
  value,
  setValue,
  tabs,
  tabsLabel,
  isHighlight,
  noBorder,
  isModal,
  noMargin,
  isScrollable,
  isNavigation,
  navTabs,
  fontSize,
  isThin,
}) => {
  const { t } = useTranslation();

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledTabs
      value={value}
      variant={isScrollable ? "scrollable" : "standard"}
      onChange={handleChange}
      aria-label={tabsLabel || ""}
      $isHighlight={isHighlight}
      $noBorder={noBorder}
      $isModal={isModal}
      $noMargin={noMargin}
      scrollButtons
      allowScrollButtonsMobile
      role={isNavigation ? "navigation" : undefined}
      $fontSize={fontSize}
      $isThin={isThin}
    >
      {!isNavigation &&
        tabs?.map((label, idx) => (
          <Tab label={t(label)} key={label} {...a11yProps(idx, tabsLabel)} />
        ))}
      {isNavigation &&
        navTabs?.map(({ label, href, badge }, idx) => (
          <LinkTab
            label={t(label)}
            href={href}
            badge={badge}
            key={label}
            {...a11yProps(idx, tabsLabel)}
          />
        ))}
    </StyledTabs>
  );
};

interface LinkTabProps {
  label: string;
  href: string;
  selected?: boolean;
  badge?: number;
}
function LinkTab(props: LinkTabProps) {
  return (
    <>
      {props.badge && props.badge > 0 ? (
        <Badge badgeContent={props.badge}>
          <Tab
            component={NavLink}
            to={props.href}
            aria-current={props.selected && "page"}
            {...props}
          />
        </Badge>
      ) : (
        <Tab
          component={NavLink}
          to={props.href}
          aria-current={props.selected && "page"}
          {...props}
        />
      )}
    </>
  );
}

function a11yProps(index: number, tabsLabel?: string) {
  return {
    id: `${tabsLabel}-${index}`,
    "aria-controls": `${tabsLabel}-tabpanel-${index}`,
  };
}

interface StyledTabsProps extends TabsProps {
  $isHighlight?: boolean;
  $noBorder?: boolean;
  $isModal?: boolean;
  $noMargin?: boolean;
  $fontSize?: string;
  $isThin?: boolean;
}
const StyledTabs = styled(Tabs)<StyledTabsProps>`
  min-height: ${({ $isThin }) => ($isThin ? "3.2rem" : "4.4rem")};
  width: 100%;

  ${({ $noBorder }) =>
    !$noBorder && `border-bottom: 1px solid ${colors.gray_200};`}

  &.MuiTabs-root {
    margin-bottom: ${({ $isModal, $noMargin }) =>
      $isModal || $noMargin ? "0" : "2rem;"};
  }

  .MuiBadge-badge {
    transform: scale(1) translate(0, 0);
  }

  .MuiTabs-flexContainer {
    ${({ $isModal }) =>
      !$isModal &&
      `& > *:not(:first-of-type) {
        margin-left: 1.2rem;
      }`}
  }

  .MuiButtonBase-root {
    font-family: ${mainFont};
  }

  .MuiTab-root {
    color: ${colors.gray_500};
    font-weight: 600;
    font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : "1.4rem")};
    text-transform: none;
    ${({ $isModal, $isThin }) =>
      $isThin
        ? "padding: 0.6rem 1.2rem;"
        : !$isModal
        ? "padding: 1.2rem;"
        : "padding: 1rem 0.4rem; min-width: 6.4rem;"}
    ${({ $isThin }) => $isThin && "min-height: 3.2rem;"}

    &.Mui-selected {
      color: ${colors.primary_600};
      ${({ $isHighlight }) =>
        $isHighlight && `background-color: ${colors.primary_50};`}
    }
  }
  .MuiTabs-indicator {
    background-color: ${colors.primary_600};
  }

  @media (min-width: 36em) {
    &.MuiTabs-root {
      margin-bottom: ${({ $isModal, $noMargin }) =>
        $isModal || $noMargin ? "0" : "2.2rem;"};
    }
  }
`;

export const TabPanel = styled.div.attrs<TabPanelProps>(({ value, index }) => ({
  role: "tabpanel",
  hidden: value !== index,
  id: `profile-tabpanel-${index}`,
  "aria-labelledby": `profile-tab-${index}`,
}))<TabPanelProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default AppTabs;
