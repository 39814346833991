import {
  FC,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import styled from "styled-components";
import { Slider, Button } from "@mui/material";
import { useMediaQuery } from "helpers/hooks";
import { AppText } from "components";
import { MainButton } from "components/Buttons";
import { colors } from "helpers/consts";
import { normolizeDecimalsForInputs } from "helpers/funcs";
import type { AvailableTradeOptions, CurrencyInterface } from "helpers/types";

interface RangeInputProps {
  value: string;
  setValue: (
    value: string,
    shouldChangeMain?: "noMainChange",
    isRight?: "isRight",
    shouldMaxChange?: "noMaxChange"
  ) => void;
  setFullValue?: Dispatch<SetStateAction<string>>;
  balance: number;
  mainCurrency?: AvailableTradeOptions | null;
  currencies: CurrencyInterface[] | null;
  checkIfError?: (
    leftValue: number,
    rightValue?: number,
    type?: "fromRange"
  ) => void;
  fees?: number;
  noMaxButton?: boolean;
  darkTheme?: boolean;
}

type SliderEvent = (_: any, value: number | number[]) => void;

const RangeInput: FC<RangeInputProps> = ({
  value,
  setValue,
  balance,
  mainCurrency,
  currencies,
  setFullValue,
  checkIfError,
  fees,
  noMaxButton,
  darkTheme,
}) => {
  const desktop = useMediaQuery("(min-width: 30em)");
  const [percentValue, setPercentValue] = useState<number>(0);

  useEffect(() => {
    if (!value) {
      return setPercentValue(0);
    }

    const getPercent = Math.floor(
      balance === 0
        ? 0
        : (Number(value) / (fees ? balance - fees : balance)) * 100
    );

    const currectPercent = getPercent > 100 ? 100 : getPercent;

    if (currectPercent === 100 && !!setFullValue) {
      setFullValue(String(fees ? balance - fees : balance));
    }

    if (currectPercent < 0) {
      setPercentValue(0);
      return;
    }
    setPercentValue(currectPercent);
  }, [value, balance, setFullValue, fees]);

  const onValueChange: SliderEvent = useCallback(
    (_, sliderValue) => {
      if (typeof sliderValue === "number") {
        const newValue =
          (fees ? balance - fees : balance) * (sliderValue / 100);

        const normolizedNewFullLeftvalue = normolizeDecimalsForInputs(
          newValue < 0 ? 0 : newValue,
          mainCurrency || "USD",
          currencies
        );

        !!setFullValue && setFullValue(String(newValue));
        if (!!checkIfError) {
          checkIfError(newValue, undefined, "fromRange");
        }
        setValue(
          normolizedNewFullLeftvalue,
          "noMainChange",
          undefined,
          "noMaxChange"
        );
      }
    },
    [
      balance,
      setValue,
      setFullValue,
      currencies,
      mainCurrency,
      checkIfError,
      fees,
    ]
  );

  return (
    <RangeInputContainer>
      {desktop && (
        <AppText
          fontSize={16}
          textAlign="center"
          minWidth="4.2rem"
          color={darkTheme ? colors.gray_400 : colors.gray_600}
        >
          {percentValue || 0}%
        </AppText>
      )}

      <SliderContainer $darkTheme={darkTheme}>
        <Slider
          defaultValue={0}
          aria-label="Default"
          value={percentValue}
          onChange={onValueChange}
        />
        <RangeButton
          $value="0"
          $isActive={true}
          onClick={() => onValueChange(null, 0)}
        />
        <RangeButton
          $value="25"
          $isActive={percentValue >= 25}
          onClick={() => onValueChange(null, 25)}
        />
        <RangeButton
          $value="50"
          $isActive={percentValue >= 50}
          onClick={() => onValueChange(null, 50)}
        />
        <RangeButton
          $value="75"
          $isActive={percentValue >= 75}
          onClick={() => onValueChange(null, 75)}
        />
        <RangeButton
          $value="100"
          $isActive={percentValue >= 100}
          onClick={() => onValueChange(null, 100)}
        />
      </SliderContainer>
      {!noMaxButton && (
        <MaxButton onClick={() => onValueChange(null, 100)}>Max</MaxButton>
      )}
    </RangeInputContainer>
  );
};

const RangeInputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.8rem 0;
  width: 100%;

  & > div {
    margin: 0 1.8rem;
  }

  & .MuiSlider-root {
    height: 0.8rem;
  }

  & .MuiSlider-thumb {
    background-color: ${colors.white};
    border: 0.15rem solid ${colors.primary_600};
    color: ${colors.primary_600};
    width: 2.4rem;
    height: 2.4rem;
    z-index: 5;

    &:hover,
    &.Mui-focusVisible {
      box-shadow: rgba(247, 144, 9, 0.16) 0px 0px 0px 8px;
    }
    &.Mui-active {
      box-shadow: rgba(247, 144, 9, 0.16) 0px 0px 0px 14px;
    }
  }

  & .MuiSlider-rail {
    background-color: ${colors.gray_300};
  }

  & .MuiSlider-track {
    background-color: ${colors.primary_600};
    border: 1px solid ${colors.primary_600};
  }
`;

interface RangeButtonInterface {
  $isActive: boolean;
  $value: string;
}
const RangeButton = styled(Button)<RangeButtonInterface>`
  position: absolute;
  top: 50%;
  left: ${({ $value }) => `${$value}%`};
  transform: translate(-50%, -50%);
  max-width: 2.4rem;
  min-width: 2.4rem;
  max-height: 2.4rem;
  min-height: 2.4rem;
  border-radius: 50%;
  color: ${colors.gray_500};
  background-color: ${({ $isActive }) =>
    $isActive ? colors.primary_600 : colors.gray_200};

  &:hover,
  &:focus {
    background-color: ${({ $isActive }) =>
      $isActive ? colors.primary_600 : colors.gray_300};
  }
`;

interface SliderContainerInterface {
  $darkTheme?: boolean;
}
const SliderContainer = styled.div<SliderContainerInterface>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 3.4rem;

  & > ${RangeButton} {
    border: 4px solid
      ${({ $darkTheme }) => ($darkTheme ? colors.dark : colors.gray_50)};
  }
`;

const MaxButton = styled(MainButton)`
  padding: 0.8rem 1.4rem;

  @media (min-width: 36em) {
    min-width: 8rem;
  }
`;

export default RangeInput;
