import { FC, useState, useEffect, useMemo, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import BasicModal from "./BasicModal";
import { Fade } from "@mui/material";
import { CloseModalButton, CurrencyButton } from "components/Buttons";
import { Select, ReadInfo } from "components/Inputs";
import { SelectItemProps } from "components/Inputs/types";
import { AppText, Preloader } from "components";
import { getCurrenciesPriority } from "helpers/funcs";
import {
  ModalContent,
  ModalHeading,
  MainContainer,
  TransactionGroup,
  InnerContent,
  InfoRows,
  QrContainer,
  QrZone,
  WarningsContainer,
} from "./styled";
import { colors } from "helpers/consts";
import { ByBitStore, CurrenciesStore } from "stores";
import ByBitCoinSelect from "./ByBitCoinSelect";
import { ModalRow } from "components/Rows";
import { ModalWarningInfo } from "components/Modals";
import { getByBitDepositAddress } from "api/byBit";
import type { ByBitGetDepositAddressReq } from "api/types/bybit";
import type {
  AvailableTradeOptions,
  ByBitDepositWithdrawAsset,
  ByBitDepositWithdrawAssetsFull,
} from "helpers/types";

interface DepositModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialVariant: AvailableTradeOptions;
}

const ByBitDepositModal: FC<DepositModalProps> = ({
  isOpen,
  onClose,
  initialVariant,
}) => {
  const { t } = useTranslation();

  const { currencies } = CurrenciesStore;
  const { balancesList, availableDeposits } = ByBitStore;

  const [chosenNetwork, setChosenNetwork] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewAddressLoading, setIsNewAddressLoading] =
    useState<boolean>(false);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [chosenAsset, setChosenAsset] = useState<AvailableTradeOptions | "">(
    ""
  );
  const [walletAddress, setWalletAddres] = useState<string>("");

  const closeSelect = () => setIsSelectOpen(false);
  const openSelect = () => setIsSelectOpen(true);

  useEffect(() => {
    if (isOpen && chosenNetwork && chosenAsset) {
      setIsLoading(true);
      const reqData: ByBitGetDepositAddressReq = {
        coin: chosenAsset,
        chain: chosenNetwork,
      };

      getByBitDepositAddress(reqData)
        .then(({ data }) => setWalletAddres(data.chains.addressDeposit))
        .catch()
        .finally(() => setIsLoading(false));
    }
  }, [chosenNetwork, isOpen, chosenAsset]);

  const networkOptions = useMemo<Array<SelectItemProps> | undefined>(() => {
    if (availableDeposits && chosenAsset) {
      return availableDeposits
        .find(({ coin }) => coin === chosenAsset)
        ?.chains.map((el) => ({
          value: el.chain,
          label: el.chainType,
        }));
    }
    return undefined;
  }, [availableDeposits, chosenAsset]);

  const networkInfo = useMemo<ByBitDepositWithdrawAsset | null>(() => {
    if (chosenNetwork && chosenAsset && availableDeposits) {
      return (
        availableDeposits
          .find(({ coin }) => coin === chosenAsset)
          ?.chains.find(({ chain }) => chain === chosenNetwork) || null
      );
    }
    return null;
  }, [chosenNetwork, chosenAsset, availableDeposits]);

  const currenciesOptions = useMemo<AvailableTradeOptions[] | null>(() => {
    if (balancesList && availableDeposits && currencies) {
      const currenciesPriority = getCurrenciesPriority(
        currencies,
        balancesList
      );
      const withdrawAvailableCoins = availableDeposits.reduce(
        (acc: AvailableTradeOptions[], el: ByBitDepositWithdrawAssetsFull) => {
          if (!acc.includes(el.coin)) {
            acc.push(el.coin);
          }
          return acc;
        },
        []
      );
      return balancesList
        .filter(({ coin }) => withdrawAvailableCoins?.includes(coin))
        .map(({ coin }) => coin)
        .sort((a, b) => currenciesPriority[b] - currenciesPriority[a]);
    }
    return null;
  }, [balancesList, availableDeposits, currencies]);

  useEffect(() => {
    if (
      isOpen &&
      chosenAsset &&
      !chosenNetwork &&
      networkOptions?.length === 1
    ) {
      setChosenNetwork(String(networkOptions[0].value));
    }
  }, [chosenAsset, networkOptions, chosenNetwork, isOpen]);

  useEffect(() => {
    if (isOpen && balancesList) {
      setChosenAsset(initialVariant);
    }
  }, [initialVariant, balancesList, isOpen]);

  const resetState = useCallback(() => {
    setChosenNetwork("");
    setIsLoading(false);
    setIsNewAddressLoading(false);
    setWalletAddres("");
  }, []);

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      onExited={resetState}
      title={t("DEPOSIT")}
    >
      <Fade in timeout={500}>
        <ModalContent>
          {(isLoading || isNewAddressLoading) && (
            <Preloader
              isStatic
              text={
                isNewAddressLoading
                  ? t("NEW_WALLET_ADDRESS_CREATING")
                  : undefined
              }
            />
          )}
          <CloseModalButton onClose={onClose} />
          <ModalHeading>{t("DEPOSIT")}</ModalHeading>
          <InnerContent>
            <MainContainer>
              <TransactionGroup>
                <AppText color={colors.gray_500}>
                  {t("CHOOSE_METHOD_ASSET")}
                </AppText>
                <CurrencyButton
                  value={chosenAsset}
                  icon={chosenAsset}
                  onClick={openSelect}
                />
              </TransactionGroup>

              {networkOptions && (
                <TransactionGroup>
                  <Select
                    variants={networkOptions}
                    value={chosenNetwork}
                    setValue={setChosenNetwork}
                    label={t("SELECT_NETWORK")}
                  />
                </TransactionGroup>
              )}
            </MainContainer>

            <WarningsContainer>
              <ModalWarningInfo
                text={`${t("CHECK_DEPOSIT_ADDRESS_1")} ${chosenAsset || ""}${
                  chosenNetwork ? ` ${chosenNetwork}` : ""
                }  ${t("CHECK_DEPOSIT_ADDRESS_2")}`}
                subText={t("SENDING_FUNDS_INFO")}
              />

              <ModalWarningInfo text={t("PROCEED_INFO")} />
            </WarningsContainer>

            <MainContainer $padding="1.6rem 1rem">
              <TransactionGroup>
                <AppText color={colors.gray_500}>
                  {t("DEPOSIT_ADDRESS")}
                </AppText>

                <QrContainer>
                  <QrZone $isBlured={!walletAddress}>
                    <QRCode
                      value={walletAddress || "placeholderQR"}
                      size={144}
                    />
                  </QrZone>
                </QrContainer>

                <AppText color={colors.gray_500} style={{ marginTop: "1rem" }}>
                  Address
                </AppText>
                <ReadInfo
                  text={walletAddress || "notRealWalletAddressJustSomeExample"}
                  isBlured={!walletAddress}
                  withCopy
                />
              </TransactionGroup>
            </MainContainer>
            <InfoRows>
              {networkInfo && (
                <ModalRow
                  keyText={t("MINIMUM_DEPOSIT")}
                  value={`${networkInfo.depositMin} ${chosenAsset}`}
                  isBold
                />
              )}
            </InfoRows>
          </InnerContent>

          <ByBitCoinSelect
            onClose={closeSelect}
            isOpen={isSelectOpen}
            options={currenciesOptions}
            setValue={setChosenAsset}
            isDeposit
          />
        </ModalContent>
      </Fade>
    </BasicModal>
  );
};

export default observer(ByBitDepositModal);
