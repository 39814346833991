import { colors } from "helpers/consts";
import styled from "styled-components";
import { TableContainer } from "@mui/material";
import { apear } from "App.styled";

export const RowInfoCell = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowGraphImage = styled.img.attrs({
  alt: "graph",
})`
  max-width: 12.8rem;
  max-height: 4.8rem;
  display: inline;
`;

interface DropDownControlsInterface {
  $darkTheme?: boolean;
}
export const DropDownControls = styled.div<DropDownControlsInterface>`
  min-height: 7.2rem;
  background-color: ${({ $darkTheme }) =>
    $darkTheme ? colors.black : colors.gray_100};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  max-width: min-content;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
`;

interface StyledTableContainerInterface {
  $withBottomBorder?: boolean;
  $darkTheme?: boolean;
}
export const StyledTableContainer = styled(
  TableContainer
)<StyledTableContainerInterface>`
  overflow: hidden;
  border-top: 1px solid
    ${({ $darkTheme }) => ($darkTheme ? colors.gray_600 : colors.gray_200)};
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  position: relative;

  .simplebar-content {
    flex-direction: row;
    flex-wrap: wrap;
  }

  table {
    border-color: ${({ $darkTheme }) =>
      $darkTheme ? colors.gray_600 : colors.gray_200};
  }

  .MuiTableCell-root {
    border-bottom: 1px solid
      ${({ $darkTheme }) => ($darkTheme ? colors.gray_600 : colors.gray_200)};
  }

  th.MuiTableCell-root {
    padding: 1.2rem 1.6rem;
  }

  ${({ $withBottomBorder }) =>
    !$withBottomBorder &&
    ` &
    .MuiTableBody-root
    > tr.MuiTableRow-root:last-of-type
    > td.MuiTableCell-root {
    border-bottom: none;
  }`}

  td.MuiTableCell-root {
    padding: 1.2rem 1.6rem;

    > img {
      max-height: 3.8rem;
    }
    > a {
      text-decoration: underline;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  td.MuiTableCell-alignRight > * {
    margin-left: auto;
  }
  td.MuiTableCell-alignLeft > * {
    margin-right: auto;
  }

  .MuiTable-root td {
    font-size: 1.4rem;
    font-weight: 500;
    font-family: "Inter", "Montserrat", sans-serif;
    > *:not(img) {
      max-width: 28rem;
    }
    > *.MuiCollapse-root {
      max-width: 100%;
      & ${DropDownControls} > * {
        display: none;
        animation: ${apear} 0.2s ease-out forwards;
      }
      &.MuiCollapse-entered {
        & ${DropDownControls} > * {
          display: flex;
        }
      }
    }
  }

  .MuiTable-root th span {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${({ $darkTheme }) =>
      $darkTheme ? colors.gray_200 : colors.gray_600};
  }

  .MuiTableCell-head span {
    white-space: nowrap;
  }
  .MuiTableRow-root.isOpenable {
    cursor: pointer;
    &:hover,
    &:focus-within {
      background-color: ${colors.gray_50};
    }
    &:active {
      background-color: ${colors.gray_100};
    }
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .MuiTableCell-root.forCollapse {
    position: sticky;
    right: 0;
    background-color: ${({ $darkTheme }) =>
      $darkTheme ? colors.dark : colors.white};
    z-index: 10;
    padding: 1.2rem;
    max-width: 7.2rem;
  }
  .MuiTableRow-root.insideDropDown {
    background-color: ${colors.gray_50};
    .MuiTableCell-root.forCollapse {
      background-color: ${colors.gray_50};
    }
  }
  .MuiCollapse-wrapperInner {
    background-color: ${({ $darkTheme }) =>
      $darkTheme ? colors.black : colors.gray_100};
    height: 7.2rem;
  }

  span:focus,
  span:focus:active {
    outline: none;
    box-shadow: 0 0 0 0.4rem rgba(13, 110, 253, 0.25);
  }

  .MuiTableSortLabel-root > svg:not(.MuiSvgIcon-root) {
    margin-left: 0.6rem;
  }

  @media (min-width: 36em) {
    th.MuiTableCell-root {
      padding: 1.2rem 2.4rem;
    }

    td.MuiTableCell-root {
      padding: 1.2rem 2.4rem;
    }
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  & > *:not(:first-child) {
    margin-left: 1.2rem;
  }
`;
