import { makeAutoObservable } from "mobx";
import { availableNoFntCurrencies } from "helpers/consts";
import type {
  CurrencyInterface,
  PricesInterface,
  MarketsInterface,
} from "helpers/types";

class Store {
  currencies: CurrencyInterface[] | null = null;
  markets: MarketsInterface[] | null = null;
  prices: PricesInterface | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrencies = (currency: CurrencyInterface[]) => {
    const filteredCurrencies = currency.filter(({ name }) =>
      availableNoFntCurrencies.includes(name)
    );
    this.currencies = filteredCurrencies;
  };

  setMarkets = (markets: MarketsInterface[]) => {
    const filteredWallets = markets.filter(
      ({ leftCurrencyName, rightCurrencyName }) =>
        availableNoFntCurrencies.includes(leftCurrencyName) &&
        availableNoFntCurrencies.includes(rightCurrencyName)
    );
    this.markets = filteredWallets;
  };

  setPrices = (prices: PricesInterface) => {
    this.prices = prices;
  };
}

const currenciesStore = new Store();
export default currenciesStore;
