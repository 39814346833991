import { FC, Dispatch, SetStateAction, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors, currencyIcons } from "helpers/consts";
import {
  ModalButton,
  BackModalButton,
  CloseModalButton,
} from "components/Buttons";
import { AppText, Checkbox } from "components";
// import { TextInput } from "components/Inputs";
import {
  toLocaleStringWithCurrency,
  normolizeDecimalsWithNoCode,
} from "helpers/funcs";
import WithdrawInfoRows from "./WithdrawInfoRows";
import {
  ModalContent,
  ModalHeading,
  InnerContentForm,
  ConfirmBlock,
  // InputContainer,
} from "../styled";
import type { AvailableTradeOptions, CurrencyInterface } from "helpers/types";

// import { ReactComponent as LockIcon } from "assets/icons/lock-unlocked.svg";

interface ConfirmWithdrawStepProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setSkipConfirm: Dispatch<SetStateAction<boolean>>;
  skipConfirm: boolean;
  onBack: () => void;
  onClose: () => void;
  currencyValue: string;
  currency: AvailableTradeOptions | null;
  fees: number;
  currencies: CurrencyInterface[] | null;
  // twoFACode: string;
  // setTwoFACode: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
}

const ConfirmWithdrawStep: FC<ConfirmWithdrawStepProps> = ({
  onSubmit,
  skipConfirm,
  setSkipConfirm,
  onBack,
  onClose,
  currencyValue,
  currency,
  fees,
  currencies,
  // twoFACode,
  // setTwoFACode,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <CloseModalButton onClose={onClose} />
        <BackModalButton onBack={onBack} />
        <ModalHeading $centered>{t("WITHDRAW_REVIEW")}</ModalHeading>
        <InnerContentForm onSubmit={(e) => onSubmit(e)}>
          <ConfirmBlock>
            {currencyIcons[currency!]
              ? currencyIcons[currency!]
              : currencyIcons.not_found}
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_700}
              lineHeight={30}
            >
              {currency}
            </AppText>
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_700}
              lineHeight={30}
            >
              {normolizeDecimalsWithNoCode(
                Number(currencyValue),
                currency || "USDT",
                currencies
              )}
              {currency !== "EUR" && (
                <AppText
                  isSpan
                  fontSize={20}
                  fontWeight={600}
                  color={colors.gray_400}
                  lineHeight={30}
                >
                  {" "}
                  {currency}
                </AppText>
              )}
            </AppText>
          </ConfirmBlock>

          <WithdrawInfoRows
            currencyValue={currencyValue}
            currency={currency}
            fees={fees}
          />

          <Checkbox
            checked={skipConfirm}
            onClick={() => setSkipConfirm(!skipConfirm)}
            label={t("DONT_SHOW_AGAIN")}
            variant="info"
          />

          {/* <InputContainer $marginTop>
            <AppText>{t("2FA Code")}</AppText>
            <TextInput
              placeholder={t("CODE")}
              value={twoFACode}
              onChange={({ target: { value } }) => setTwoFACode(value)}
              leftIcon={<LockIcon />}
              autoComplete="off"
              name="2fa-code"
            />
          </InputContainer> */}

          <ModalButton type="submit" disabled={isLoading}>
            {t("CONFIRM")}{" "}
            {toLocaleStringWithCurrency(
              Number(currencyValue),
              currency || "USDT",
              currencies
            )}{" "}
            {fees ? `+ ${fees} ${currency || "USDT"} Fees` : ""}
          </ModalButton>
        </InnerContentForm>
      </ModalContent>
    </Fade>
  );
};

export default ConfirmWithdrawStep;
