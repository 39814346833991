import {
  FC,
  useEffect,
  useState,
  useMemo,
  useCallback,
  FormEvent,
} from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import BasicModal from "../BasicModal";
import MainStakeStep from "./MainStakeStep";
import { Preloader } from "components";
import ConfirmUnstakeStep from "./ConfirmUnstakeStep";
import SuccessStakeStep from "./SuccessStakeStep";
import { stake, unstake } from "api/stake";
import { CurrenciesStore, StakesStore, GlobalStore } from "stores";
import type {
  ModalStepsType,
  StakeType,
  AvailableTradeOptions,
  StakeStrategyInterface,
  StakeInterface,
} from "helpers/types";
import type {
  StakeRequestInterface,
  UnstakeRequestInterface,
} from "api/types/stake";

interface StakeModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialCoin?: AvailableTradeOptions;
  initialType?: StakeType;
  initialStakeStrategyId?: number;
  forwardUnstake?: StakeInterface | null;
  isforwardUnstake?: boolean;
  manualTableDataChange?: (type: StakeType, item: StakeInterface) => void;
}
const StakeModal: FC<StakeModalProps> = ({
  isOpen,
  onClose,
  initialCoin,
  initialStakeStrategyId,
  initialType = "stake",
  isforwardUnstake,
  forwardUnstake,
  manualTableDataChange,
}) => {
  const { t } = useTranslation();
  const { currencies, prices } = CurrenciesStore;
  const { stakeSrategies, setActiveStakes, activeStakes } = StakesStore;
  const [step, setStep] = useState<ModalStepsType>("main");
  const [stakeValue, setStakeValue] = useState<string>("");
  const [fullStakeValue, setFullStakeValue] = useState<string>("");
  const [strategy, setStrategy] = useState<StakeStrategyInterface | null>(null);
  const [currentUnstake, setCurrentUnstake] = useState<StakeInterface | null>(
    null
  );
  const [tabValue, setTabValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen && isforwardUnstake && forwardUnstake) {
      setStep("confirm");
      setCurrentUnstake(forwardUnstake);
    }
  }, [forwardUnstake, isOpen, isforwardUnstake]);

  useEffect(() => {
    if (isOpen && initialType !== "stake") {
      setTabValue(1);
    }
  }, [initialType, isOpen]);

  const stakeType = useMemo<StakeType>(
    () => (tabValue === 0 ? "stake" : "unstake"),
    [tabValue]
  );

  const onStakeSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stakeSrategies || stakeSrategies.length === 0) {
      return;
    }
    const stakeData: StakeRequestInterface = {
      strategyId: strategy!.id,
      amount: fullStakeValue,
    };

    setIsLoading(true);

    stake(stakeData)
      .then(({ data }) => {
        const newActiveStakes = activeStakes ? [...activeStakes, data] : [data];
        if (!!manualTableDataChange) {
          manualTableDataChange("stake", data);
        }
        setActiveStakes(newActiveStakes);
        setStep("success");
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const onUnstakeSubmit = (activeStake?: StakeInterface) => {
    if (step === "main" && activeStake) {
      setCurrentUnstake(activeStake);
      setStep("confirm");
      return;
    }

    const unstakeData: UnstakeRequestInterface = {
      stakingUuid: currentUnstake!.uuid,
    };

    setIsLoading(true);

    unstake(unstakeData)
      .then(({ data }) => {
        if (!!manualTableDataChange) {
          manualTableDataChange("unstake", data);
        }

        if (!activeStakes) {
          setActiveStakes([data]);
          setStep("success");
          return;
        }

        const newActiveStakes = [...activeStakes].filter(
          ({ uuid }) => uuid !== data.uuid
        );

        setActiveStakes(newActiveStakes);
        setStep("success");
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!stakeSrategies) {
      return;
    }
    if (initialStakeStrategyId) {
      const initialStrategy = stakeSrategies?.find(
        ({ id }) => id === initialStakeStrategyId
      );
      if (initialStrategy) {
        setStrategy(initialStrategy);
        return;
      }
    }
    if (initialCoin) {
      const initialStrategy = stakeSrategies?.find(
        ({ currencyName }) => currencyName === initialCoin
      );
      if (initialStrategy) {
        setStrategy(initialStrategy);
        return;
      }
    }
    setStrategy(stakeSrategies[0]);
  }, [initialCoin, stakeSrategies, isOpen, initialStakeStrategyId]);

  const closeModal = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const resetState = useCallback(() => {
    if (step === "success") {
      setCurrentUnstake(null);
    }
    setStep("main");
    setTabValue(0);
    setStakeValue("");
    setFullStakeValue("");
    setStrategy(
      stakeSrategies?.find(
        ({ currencyName }) => currencyName === initialCoin
      ) || null
    );
  }, [step, initialCoin, stakeSrategies]);

  const coinCost = useMemo<number>(() => {
    const mainCost = prices?.[`${strategy?.currencyName}/USD`]?.close;
    return Number(mainCost) || 0;
  }, [prices, strategy]);

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={resetState}
      onClose={closeModal}
      title={t("STAKE")}
    >
      {isLoading && <Preloader isStatic />}
      {step === "main" && (
        <MainStakeStep
          onSubmit={onStakeSubmit}
          onUnstakeSubmit={onUnstakeSubmit}
          stakeValue={stakeValue}
          strategy={strategy}
          setStakeValue={setStakeValue}
          setStrategy={setStrategy}
          onClose={closeModal}
          stakeType={stakeType}
          currencies={currencies}
          tabValue={tabValue}
          setTabValue={setTabValue}
          setFullStakeValue={setFullStakeValue}
          cost={coinCost}
          isLoading={isLoading}
        />
      )}
      {step === "confirm" && (
        <ConfirmUnstakeStep
          onSubmit={onUnstakeSubmit}
          currentUntake={currentUnstake}
          onBack={() => {
            setStep("main");
            setCurrentUnstake(null);
          }}
          onClose={closeModal}
          currencies={currencies}
          cost={coinCost}
          isforwardUnstake={isforwardUnstake}
          isLoading={isLoading}
          stakeSrategies={stakeSrategies}
        />
      )}
      {step === "success" && (
        <SuccessStakeStep
          stakeValue={stakeValue}
          strategy={strategy}
          onClose={closeModal}
          stakeType={stakeType}
          currentUnstake={currentUnstake}
          currencies={currencies}
          cost={coinCost}
        />
      )}
    </BasicModal>
  );
};

export default observer(StakeModal);
