import { createGlobalStyle, keyframes } from "styled-components";
import { colors, mainFont } from "helpers/consts";

interface GlobalStyleProps {
  $isDarkTheme?: boolean;
}
export const AppStyles = createGlobalStyle<GlobalStyleProps>`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${mainFont};
    font-size: 62.5%;
    font-style: normal;
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    background-color: ${({ $isDarkTheme }) =>
      $isDarkTheme ? colors.dark : colors.gray_50};
    color: ${colors.gray_900};
    min-width: 32rem;
    padding: 0;
    max-width: 100vw;
    min-height: 100dvh;
    overflow: hidden;

    &::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
    }
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    .simplebar-content {
      width: 100%;
      height: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .MuiBadge-badge {
      font-size: 1rem;
      background-color: ${colors.error_500};
      color: ${colors.white};
    }
}

  #root {
    width: 100%;
    min-width: 32rem;
    min-height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    padding: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  ul > li {
    list-style: none;
  }

  ol {
    padding-inline-start: 0;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  svg {
    max-width: 100%;
  }

  td,
  th,
  p,
  button,
  li,
  span,
  a,
  div,
  .MuiMenu-root * {
    font-size: 1.4rem;
    font-weight: 500;
    font-family: ${mainFont};
  }

  tr,
  td,
  th,
  p,
  button,
  ul,
  ol,
  li,
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }

  input,
  select,
  textarea,
  .MuiTextField-root .MuiInputLabel-root,
  .MuiTextField-root .MuiInputBase-input,
  .MuiTabs-root button,
  .MuiButton-root,
  .MuiTypography-root {
    font-family: ${mainFont};
    text-transform: none;
  }

  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-family: ${mainFont};
  }
  button:disabled {
    cursor: initial;
  }

  input:focus,
  input:focus:active,
  textarea:focus,
  textarea:focus:active,
  button:focus,
  button:focus:active,
  a:focus,
  a:focus:active {
    outline: none;
  }

  button:focus,
  button:focus:active,
  a:focus,
  a:focus:active {
    box-shadow: 0 0 0 0.4rem rgba(13, 110, 253, 0.25);
  }

  .MuiPopper-root {
    .MuiButtonBase-root,
    .MuiTypography-root {
      font-size: 1.2rem;

      > svg {
        width: 2.2rem;
        height: 2.2rem;
      }
    }
    .MuiPickersYear-yearButton {
      font-size: 1.4rem;
    }
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    padding-right: 0;
  }
  .MuiBackdrop-root:not(.MuiBackdrop-invisible) {
    backdrop-filter: blur(4px);
    background-color: rgba(16, 24, 40, 0.8);
  }
  .MuiDrawer-paper {
    overflow: hidden;
  }
`;

export const apear = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;
