import { CoinCell, StakingControls } from "components/Table";
import { normolizeDecimalsWithNoCode } from "helpers/funcs";
import { MainButton } from "components/Buttons";
import { StakingTypeCell, PairCell } from "components/Table";
import routesPaths from "routes/routesPaths";
import type { Data, HeadCell } from "components/Table/types";
import type { StakeInterface, CurrencyInterface } from "helpers/types";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import graphUp from "assets/images/graphUp.svg";

export const headCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "asset",
    label: "ASSET",
  },
  {
    id: "date",
    label: "DATE",
  },
  {
    id: "stakeAmount",
    label: "AMOUNT",
    align: "right",
  },
  // {
  //   id: "cost",
  //   align: "right",
  //   label: "COST",
  // },
  {
    id: "status",
    align: "right",
    label: "STATUS",
  },
];

export const activeStakesHeadCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "asset",
    label: "ASSET",
  },
  {
    id: "date",
    label: "DATE",
  },
  {
    id: "stakeAmount",
    label: "AMOUNT",
    align: "right",
  },
  {
    id: "rewardDate",
    align: "right",
    label: "NEXT_REWARD_DATE",
  },
  {
    id: "actions",
    align: "right",
    noSort: true,
  },
];

export const dummyHeadCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "date",
    label: "DATE",
  },
  {
    id: "type",
    label: "TYPE",
  },
  {
    id: "pool",
    label: "POOL",
  },
  {
    id: "asset",
    label: "ASSET",
  },
  {
    id: "cost",
    align: "right",
    label: "COST",
  },
  {
    id: "status",
    align: "right",
    label: "STATUS",
  },
];

export const tradesHeadCells: readonly HeadCell[] = [
  {
    id: "type",
    label: "Bot type",
  },
  {
    id: "assets",
    label: "Market",
  },
  {
    id: "ratio",
    label: "Sharp ratio",
    align: "right",
  },
  {
    id: "apr",
    label: "APR",
    align: "right",
  },
  {
    id: "graph",
    label: "Graph",
  },
  {
    id: "drawdown",
    align: "right",
    label: "Max Drawdown",
  },
  {
    id: "funds",
    align: "right",
    label: "Funds",
  },
  {
    id: "duration",
    align: "right",
    label: "Duration",
  },
  {
    id: "actions",
    align: "right",
    noSort: true,
  },
];

export const dummyData: Data[] = [
  {
    id: {
      value: "QGHJKL",
      align: "left",
      type: "withCopy",
    },
    date: {
      value: new Date("Tue Jul 18 2023 11:02:29"),
      type: "date",
      align: "left",
    },
    type: {
      value: "Staking",
      type: "component",
      component: <StakingTypeCell type="stake" />,
      align: "left",
    },
    pool: { value: "Golden Million", align: "left" },
    asset: { value: "Staked Tether USD", subText: "USDT", align: "left" },
    cost: { value: "10,000.00", subText: "USDT" },
    status: {
      value: "success",
      status: "success",
      subText: "status",
      type: "chip",
      align: "right",
    },
  },
  {
    id: {
      value: "QGHJKJ",
      align: "left",
      type: "withCopy",
    },
    date: {
      value: new Date("Tue Jul 17 2023 11:02:29"),
      type: "date",
      align: "left",
    },
    type: {
      value: "Staking",
      type: "component",
      component: <StakingTypeCell type="stake" />,
      align: "left",
    },
    pool: { value: "Golden Million", align: "left" },
    asset: { value: "Staked Tether USD", subText: "USDT", align: "left" },
    cost: { value: "5,000.00", subText: "USDT" },
    status: {
      value: "success",
      status: "success",
      subText: "status",
      type: "chip",
      align: "right",
    },
  },
  {
    id: {
      value: "QGHJKN",
      align: "left",
      type: "withCopy",
    },
    date: {
      value: new Date("Tue Jul 16 2023 11:02:29"),
      type: "date",
      align: "left",
    },
    type: {
      value: "Staking",
      type: "component",
      component: <StakingTypeCell type="stake" />,
      align: "left",
    },
    pool: { value: "Golden Million", align: "left" },
    asset: { value: "Staked Tether USD", subText: "USDT", align: "left" },
    cost: { value: "5,000.00", subText: "USDT" },
    status: {
      value: "success",
      status: "success",
      subText: "status",
      type: "chip",
      align: "right",
    },
  },
];

export const copyTradingTransactionsDummyData: Data[] = [
  {
    id: {
      value: "QGHJKL",
      align: "left",
      type: "withCopy",
    },
    date: {
      value: new Date("Tue Jul 18 2023 13:02:29"),
      type: "date",
      align: "left",
    },
    type: {
      value: "Reward",
      type: "component",
      component: <StakingTypeCell type="reward" />,
      align: "left",
    },
    pool: { value: "Copy trading", align: "left" },
    asset: { value: "Staked Tether USD", subText: "USDT", align: "left" },
    cost: { value: "606.25", subText: "USDT" },
    status: {
      value: "success",
      status: "success",
      subText: "status",
      type: "chip",
      align: "right",
    },
  },
  {
    id: {
      value: "QGHJKL",
      align: "left",
      type: "withCopy",
    },
    date: {
      value: new Date("Tue Jul 18 2023 12:02:29"),
      type: "date",
      align: "left",
    },
    type: {
      value: "Reward",
      type: "component",
      component: <StakingTypeCell type="reward" />,
      align: "left",
    },
    pool: { value: "Copy trading", align: "left" },
    asset: { value: "Staked Tether USD", subText: "USDT", align: "left" },
    cost: { value: "353.75", subText: "USDT" },
    status: {
      value: "success",
      status: "success",
      subText: "status",
      type: "chip",
      align: "right",
    },
  },
  {
    id: {
      value: "QGHJKL",
      align: "left",
      type: "withCopy",
    },
    date: {
      value: new Date("Tue Jul 18 2023 11:02:29"),
      type: "date",
      align: "left",
    },
    type: {
      value: "Staking",
      type: "component",
      component: <StakingTypeCell type="stake" />,
      align: "left",
    },
    pool: { value: "Copy trading", align: "left" },
    asset: { value: "Staked Tether USD", subText: "USDT", align: "left" },
    cost: { value: "7,149.50", subText: "USDT" },
    status: {
      value: "success",
      status: "success",
      subText: "status",
      type: "chip",
      align: "right",
    },
  },
  {
    id: {
      value: "QGHJKJ",
      align: "left",
      type: "withCopy",
    },
    date: {
      value: new Date("Tue Jul 17 2023 11:02:29"),
      type: "date",
      align: "left",
    },
    type: {
      value: "Staking",
      type: "component",
      component: <StakingTypeCell type="stake" />,
      align: "left",
    },
    pool: { value: "Copy trading", align: "left" },
    asset: { value: "Staked Tether USD", subText: "USDT", align: "left" },
    cost: { value: "6,000.50", subText: "USDT" },
    status: {
      value: "success",
      status: "success",
      subText: "status",
      type: "chip",
      align: "right",
    },
  },
  {
    id: {
      value: "QGHJKN",
      align: "left",
      type: "withCopy",
    },
    date: {
      value: new Date("Tue Jul 16 2023 11:02:29"),
      type: "date",
      align: "left",
    },
    type: {
      value: "Staking",
      type: "component",
      component: <StakingTypeCell type="stake" />,
      align: "left",
    },
    pool: { value: "Copy trading", align: "left" },
    asset: { value: "Staked Tether USD", subText: "USDT", align: "left" },
    cost: { value: "6,850.00", subText: "USDT" },
    status: {
      value: "success",
      status: "success",
      subText: "status",
      type: "chip",
      align: "right",
    },
  },
];

export const tradingInfoDummyData: Data[] = [
  {
    type: {
      value: "Custom Bots",
      align: "left",
    },
    assets: {
      value: "USDT/BTC",
      component: <PairCell from="USDT" to="BTC" text="USDT/BTC" />,
      align: "left",
      type: "component",
    },
    ratio: { value: "30.5" },
    apr: { type: "chip", value: 123.44 },
    graph: { type: "image", value: 0.44, image: graphUp, align: "left" },
    drawdown: { type: "chip", value: 0, align: "right", subText: "value" },
    funds: { value: "511,150", subText: "USDT" },
    duration: { value: "29 days" },
    actions: {
      value: "",
      type: "controls",
      component: (
        <StakingControls
          linkTo={routesPaths.copyTradingStake}
          initialCoin="USDC"
        />
      ),
    },
  },
  {
    type: {
      value: "Custom Bots",
      align: "left",
    },
    assets: {
      value: "USDT/ETH",
      component: <PairCell from="USDT" to="ETH" text="USDT/ETH" />,
      align: "left",
      type: "component",
    },
    ratio: { value: "29.8" },
    apr: { type: "chip", value: 98.82 },
    graph: { type: "image", value: 0.44, image: graphUp, align: "left" },
    drawdown: { type: "chip", value: 0, align: "right", subText: "value" },
    funds: { value: "301,120", subText: "USDT" },
    duration: { value: "54 days" },
    actions: {
      value: "",
      type: "controls",
      component: (
        <StakingControls
          linkTo={routesPaths.copyTradingStake}
          initialCoin="USDC"
        />
      ),
    },
  },
  {
    type: {
      value: "Custom Bots",
      align: "left",
    },
    assets: {
      value: "USDT/TRX",
      component: <PairCell from="USDT" to="TRX" text="USDT/TRX" />,
      align: "left",
      type: "component",
    },
    ratio: { value: "25.3" },
    apr: { type: "chip", value: 55.5 },
    graph: { type: "image", value: 0.44, image: graphUp, align: "left" },
    drawdown: { type: "chip", value: 0, align: "right", subText: "value" },
    funds: { value: "32,000", subText: "USDT" },
    duration: { value: "12 days" },
    actions: {
      value: "",
      type: "controls",
      component: (
        <StakingControls
          linkTo={routesPaths.copyTradingStake}
          initialCoin="USDC"
          isUnstakeDisabled
        />
      ),
    },
  },
  {
    type: {
      value: "Custom Bots",
      align: "left",
    },
    assets: {
      value: "USDT/DOT",
      component: <PairCell from="USDT" to="DOT" text="USDT/DOT" />,
      align: "left",
      type: "component",
    },
    ratio: { value: "15.08" },
    apr: { type: "chip", value: 8.82 },
    graph: { type: "image", value: 0.44, image: graphUp, align: "left" },
    drawdown: { type: "chip", value: 0, align: "right", subText: "value" },
    funds: { value: "11,200", subText: "USDT" },
    duration: { value: "19 hours" },
    actions: {
      value: "",
      type: "controls",
      component: (
        <StakingControls
          linkTo={routesPaths.copyTradingStake}
          initialCoin="USDC"
          isUnstakeDisabled
        />
      ),
    },
  },
  {
    type: {
      value: "Custom Bots",
      align: "left",
    },
    assets: {
      value: "USDT/ATOM",
      component: <PairCell from="USDT" to="ATOM" text="USDT/ATOM" />,
      align: "left",
      type: "component",
    },
    ratio: { value: "0.01" },
    apr: { type: "chip", value: -3.59 },
    graph: { type: "image", value: 0.44, image: graphUp, align: "left" },
    drawdown: {
      type: "chip",
      value: -3.59,
      align: "right",
      subText: "value",
    },
    funds: { value: "2,000", subText: "USDT" },
    duration: { value: "3 hours" },
    actions: {
      value: "",
      type: "controls",
      component: (
        <StakingControls
          linkTo={routesPaths.copyTradingStake}
          initialCoin="USDC"
          isUnstakeDisabled
        />
      ),
    },
  },
];

export const getData: (
  activeStake: StakeInterface,
  currencies: CurrencyInterface[] | null
) => Data = (activeStake, currencies) => {
  const tableData: Data = {};

  tableData.id = {
    value: activeStake.uuid,
    visibleValue: activeStake.uuid.slice(0, 6).toUpperCase(),
    align: "left",
    type: "withCopy",
  };

  tableData.assets = {
    value: activeStake.currencyName,
    component: (
      <CoinCell
        minWidth="12rem"
        coin={activeStake.currencyName}
        text={activeStake.currencyName}
      />
    ),
    type: "component",
  };

  tableData.date = {
    value: new Date(activeStake.createdAt),
    type: "date",
    align: "left",
  };

  tableData.stakeAmount = {
    value: normolizeDecimalsWithNoCode(
      activeStake.amount,
      activeStake.currencyName,
      currencies
    ),
    subText: activeStake.currencyName,
  };

  tableData.status = {
    value: activeStake.status,
    status: activeStake.status === "ACTIVE" ? "success" : "failed",
    subText: "status",
    type: "chip",
    align: "right",
  };

  return tableData;
};

export const getActiveStakesData: (
  activeStake: StakeInterface,
  currencies: CurrencyInterface[] | null,
  openModal: (activeStake: StakeInterface) => void
) => Data = (activeStake, currencies, openModal) => {
  const tableData: Data = {};

  tableData.id = {
    value: activeStake.uuid,
    visibleValue: activeStake.uuid.slice(0, 6).toUpperCase(),
    align: "left",
    type: "withCopy",
  };

  tableData.assets = {
    value: activeStake.currencyName,
    component: (
      <CoinCell
        minWidth="12rem"
        coin={activeStake.currencyName}
        text={activeStake.currencyName}
      />
    ),
    type: "component",
  };

  tableData.date = {
    value: new Date(activeStake.createdAt),
    type: "date",
    align: "left",
  };

  tableData.stakeAmount = {
    value: normolizeDecimalsWithNoCode(
      activeStake.amount,
      activeStake.currencyName,
      currencies
    ),
    subText: activeStake.currencyName,
  };

  tableData.rewardDate = {
    value: new Date(activeStake.nextRewardDate),
    type: "date",
    align: "right",
    semiType: "noTime",
  };

  tableData.actions = {
    value: "close",
    type: "component",
    align: "right",
    component: (
      <MainButton variant="contained" onClick={() => openModal(activeStake)}>
        <CloseIcon /> Unstake
      </MainButton>
    ),
  };

  return tableData;
};

export const millionHowWorks: string[] = [
  "Staking pools allow crypto holders to earn passive income by contributing to a pool of funds that collectively earn block validation rewards from a Proof of Stake (PoS) blockchain.",
  "Individually, small stakers can’t access the rewards available to validators of PoS chains. But if they collectively add their funds to a pool, the pool is large enough to generate rewards paid out to contributing stakers in proportion to the amount committed.",
  "PoS is one method blockchains use to agree on the validity of information recorded in new data blocks. By requiring anyone who wants to propose a new block ( a validator) to have a financial stake in the system, PoS discourages dishonest behaviour.",
];

export const millionBenefits: string[] = [
  "Passive income: Staking pools provide a way of generating a passive income on your crypto funds. ",
  "Participation: By joining a staking pool, you are helping to secure the underlying blockchain and playing an active role in the crypto ecosystem.",
  "Education: Non-custodial staking pools give you a better insight into how the validation process for PoS blockchain works, providing a useful form of hands-on education.",
];

export const copyTradingWorks: string =
  "<p>We use our computing power to create and comprehensively test mathematical linear equations on each financial markets. Only those mathematical expressions for evaluating a number series are selected that have historically shown the best results. Due to the fact that we perform millions of calculations every day, this allows us to select a large number of presumably profitable financial models. Thus, our trading strategy is built from a large number of algorithms, which allows us to create a diversified investment portfolio and intelligently distribute risk.</p>";

export const copyTradingBenefits: string =
  "<p>The proposed trading model is a Long/Short portfolio consisting of more than 450 trend-following algorithms. Each of the algorithms is a computer program that manages a small part of the funds from the total portfolio, which minimizes the risk of losing the entire capital. This splitting of the deposit provides an undeniable advantage: algorithms that win earn more each time, and those that lose lose less. Each algorithm always uses a stop order, and therefore the average risk on each trade is 0.01% of the total portfolio. Leverage used: 1 to 5. Since the portfolio is managed by a pre-trained robot, this reduces, or completely eliminates, the human factor when making financial decisions.</p>";

export const copyTradingCommissions: string =
  "<p>Sc AF algorithms can be called volatility hunters. Increased volatility is a fairly rare event that does not happen every day, so we keep positions open for 1-3 months, and the recommended portfolio management period is 12 months.\n<br/><br/>We retain 2 types of commissions:\n<br/><br/>Management fee - 2% of the deposit annually, which is charged at the beginning of work, regardless of the final result.\n<br/><br/>Performance commission - 20%, which is withheld at the end of the trading year from profits. If no profit is made, then there is no performance fee.</p>";
