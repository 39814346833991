import { useRef, useLayoutEffect, useMemo } from "react";
import type { Timer } from "helpers/types";

function throttle(func: (...args: any[]) => void, limit = 300) {
  let lastFunc: Timer;
  let lastRan: number;
  return (...args: any[]) => {
    const now = Date.now();
    if (!lastRan) {
      func(...args); // Call the function with the provided arguments
      lastRan = now;
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (now - lastRan >= limit) {
          func(...args); // Call the function with the provided arguments
          lastRan = Date.now();
        }
      }, limit - (now - lastRan));
    }
  };
}

function useThrottle(callback: (...args: any[]) => void, limit: number) {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return useMemo(
    () => throttle((...args: any[]) => callbackRef.current(...args), limit),
    [limit]
  );
}

export default useThrottle;
