import axios from "axios";
import { CardsStore, GlobalStore } from "stores";
import type {
  CredictcoAccessStatus,
  OrderCreditcoCardReq,
  CredictcoCardPlanListRes,
  DeliveryAddressesRes,
  CreateDeliveryAddressesReq,
  CreditcoCardBalanceRes,
  CreditcoCardPinRes,
  CreditcoCardTopUpReq,
  CreditcoCardWithdrawReq,
  CreditcoCardTransactionRes,
  CreditcoCardTransactionsReq,
  CreditcoCardTransactionsRes,
  CreditcoCardsAllTransactionsReq,
  CreditcoCardsAllTransactionsRes,
  ConvertCardToPhysicalReq,
} from "./types/creditco";
import type {
  CreditcoCard,
  CreditcoCardTemplate,
  CredictcoCardDetails,
  CreditcoCardsPurchaseHistory,
  DeliveryAddress,
} from "helpers/types";

export const getUsersCreditcoCardsList = async () =>
  axios
    .get<CredictcoCardDetails[]>("/creditco/card/list")
    .then(({ data }) => {
      CardsStore.setCryptoCardsList(data);
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        GlobalStore.setError(err.response.data.message);
      }
    });

export const getCreditcoCardPlansList = async () =>
  axios.get<CredictcoCardPlanListRes>("/creditco/card-plan/list", {
    params: { page: 0, itemsPerPage: 20 },
  });

export const getCreditcoCardPlan = async (cardPlanId: number) =>
  axios.get<CreditcoCardTemplate>(`/creditco/card-plan/${cardPlanId}`);

export const getCreditcoAccessStatus = async () =>
  axios.get<CredictcoAccessStatus>("/creditco/kyc/status");

export const getCreditcoCardDetails = async (cardId: number) =>
  axios.get<CredictcoCardDetails>(`/creditco/card/${cardId}/details`);

export const getCreditcoCardWithCredentials = async (
  cardId: number,
  oneTimePassword: string
) =>
  axios.get<CredictcoCardDetails>(`/creditco/card/${cardId}/with-credentials`, {
    params: { oneTimePassword },
  });

export const getCreditcoCardPinCode = async (
  cardId: number,
  oneTimePassword: string
) =>
  axios.get<CreditcoCardPinRes>(`/creditco/card/${cardId}/pin-code`, {
    params: { oneTimePassword },
  });

export const getCreditcoCardBalance = async (cardId: number) =>
  axios.get<CreditcoCardBalanceRes>(`/creditco/card/${cardId}/balance`);

export const requestKycAccess = async () =>
  axios.post("/creditco/kyc/request-access");

export const orderCreditcoCard = async (data: OrderCreditcoCardReq) =>
  axios.post<CreditcoCard>("/creditco/card/order", data);

export const getDeliveryAddresses = async () =>
  axios.get<DeliveryAddressesRes>("/delivery-address/list", {
    params: { page: 0, itemsPerPage: 100 },
  });

export const createDeliveryAddress = async (data: CreateDeliveryAddressesReq) =>
  axios.post<DeliveryAddress>("/delivery-address", data);

export const topUpCreditcoCard = async (
  cardId: number,
  data: CreditcoCardTopUpReq
) =>
  axios.post<CreditcoCardTransactionRes>(
    `/creditco/card/${cardId}/top-up`,
    data
  );

export const withdrawCreditcoCard = async (
  cardId: number,
  data: CreditcoCardWithdrawReq
) =>
  axios.post<CreditcoCardTransactionRes>(
    `/creditco/card/${cardId}/withdraw`,
    data
  );

export const getCardTransactions = async (data: CreditcoCardTransactionsReq) =>
  axios.get<CreditcoCardTransactionsRes>(
    `/creditco/card/${data.cardId}/transaction/list`,
    {
      params: {
        page: data.page,
        itemsPerPage: data.itemsPerPage,
        search: data.search || "",
      },
    }
  );

export const getAllCardsTransactions = async (
  data: CreditcoCardsAllTransactionsReq
) =>
  axios.get<CreditcoCardsAllTransactionsRes>(
    `/creditco/card/transaction/list`,
    {
      params: {
        page: data.page,
        itemsPerPage: data.itemsPerPage,
        search: data.search || "",
      },
    }
  );

export const getCardsPurchaseHistory = async () =>
  axios.get<CreditcoCardsPurchaseHistory[]>(
    "/creditco/card/purchase-history/list"
  );

export const convertCardToPhysical = async (data: ConvertCardToPhysicalReq) =>
  axios.post("/creditco/card/convert-card-to-physical", data);

export const activatePhysicalCard = async (cardId: string, code: string) =>
  axios.post(`/creditco/card/${cardId}/activate`, {
    publicToken: code,
  });

export const updateCardNote = async (cardId: number, note: string) =>
  axios.put<CreditcoCard>(`/creditco/card/${cardId}/add-note`, { note });
