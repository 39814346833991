import { FC, Suspense, lazy } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Preloader } from "components";
import { UserStore } from "stores";
import routesPaths from "./routesPaths";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

// this function below is for catching production error - when it is already
// new version with new Chunks, but user is trying to navigate through
// website, that already stored old chunks - what will cause chunk error
const lazyRetry = function (
  componentImport: () => Promise<{ default: React.ComponentType<any> }>
) {
  return new Promise<{ default: React.ComponentType<any> }>(
    (resolve, reject) => {
      // check if the window has already been refreshed
      const hasRefreshed = JSON.parse(
        window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
      );
      // try to import the component
      componentImport()
        .then((component) => {
          window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
          if (component === undefined) {
            window.sessionStorage.setItem("retry-lazy-refreshed", "true");
            return window.location.reload(); // refresh the page
          }
          resolve(component);
        })
        .catch((error: Error) => {
          if (!hasRefreshed) {
            // not been refreshed yet
            window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
            return window.location.reload(); // refresh the page
          }
          reject(error); // Default error behaviour as already tried refresh
        });
    }
  );
};

const LoginPage: FC = lazy(() =>
  lazyRetry(() => import("pages/LoginPage" /* webpackChunkName: "LoginPage" */))
);
// const SignUpPage: FC = lazy(() =>
//   lazyRetry(
//     () => import("pages/SignUpPage" /* webpackChunkName: "SignUpPage" */)
//   )
// );
const ResetPasswordPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/ResetPasswordPage" /* webpackChunkName: "ResetPasswordPage" */
      )
  )
);
const ForgotPasswordPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/ForgotPasswordPage" /* webpackChunkName: "ForgotPasswordPage" */
      )
  )
);
const EmailConfirmPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/EmailConfirmPage" /* webpackChunkName: "EmailConfirmPage" */
      )
  )
);
const VerifyEmailPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import("pages/VerifyEmailPage" /* webpackChunkName: "VerifyEmailPage" */)
  )
);
const DashbaordPage: FC = lazy(() =>
  lazyRetry(
    () => import("pages/DashboardPage" /* webpackChunkName: "DashbaordPage" */)
  )
);
const TradePage: FC = lazy(() =>
  lazyRetry(() => import("pages/TradePage" /* webpackChunkName: "TradePage" */))
);
const NewOrderPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/TradePage/innerPages/NewOrder" /* webpackChunkName: "NewOrderPage" */
      )
  )
);
const OpenOrdersPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/TradePage/innerPages/OpenOrders" /* webpackChunkName: "OpenOrdersPage" */
      )
  )
);
const OrdersHistoryPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/TradePage/innerPages/OrdersHistory" /* webpackChunkName: "OrdersHistoryPage" */
      )
  )
);

// const EarningPage: FC = lazy(() =>
//   lazyRetry(
//     () => import("pages/EarningPage" /* webpackChunkName: "EarningPage" */)
//   )
// );
// const StakingPage: FC = lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         "pages/EarningPage/innerPages/Staking" /* webpackChunkName: "StakingPage" */
//       )
//   )
// );
// const GoldenMillionPage: FC = lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         "pages/EarningPage/innerPages/GoldenMillion" /* webpackChunkName: "GoldenMillionPage" */
//       )
//   )
// );
// const DashboardCopyTrading: FC = lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         "pages/EarningPage/innerPages/DashboardCopyTrading" /* webpackChunkName: "DashboardCopyTrading" */
//       )
//   )
// );

// const CopyTradingPage: FC = lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         "pages/EarningPage/innerPages/CopyTrading" /* webpackChunkName: "CopyTradingPage" */
//       )
//   )
// );

const WalletPage: FC = lazy(() =>
  lazyRetry(
    () => import("pages/WalletPage" /* webpackChunkName: "WalletPage" */)
  )
);
const HistoryPage: FC = lazy(() =>
  lazyRetry(
    () => import("pages/HistoryPage" /* webpackChunkName: "HistoryPage" */)
  )
);

// const FuturesPage: FC = lazy(() =>
//   lazyRetry(
//     () => import("pages/FuturesPage" /* webpackChunkName: "FuturesPage" */)
//   )
// );
// const FuturesTradePage: FC = lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         "pages/FuturesPage/innerPages/FuturesTrade" /* webpackChunkName: "FuturesTradePage" */
//       )
//   )
// );
// const FuturesBalancesPage: FC = lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         "pages/FuturesPage/innerPages/FuturesBalances" /* webpackChunkName: "FuturesBalancesPage" */
//       )
//   )
// );
// const GamesPage: FC = lazy(() =>
//   lazyRetry(() => import("pages/GamesPage" /* webpackChunkName: "GamesPage" */))
// );
const ProfilePage: FC = lazy(() =>
  lazyRetry(
    () => import("pages/ProfilePage" /* webpackChunkName: "ProfilePage" */)
  )
);
const ProfileSettingsPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/ProfilePage/innerPages/ProfileSettings" /* webpackChunkName: "ProfileSettingsPage" */
      )
  )
);
const VerificationPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/ProfilePage/innerPages/Verification" /* webpackChunkName: "VerificationPage" */
      )
  )
);
// const ActivityLogPage: FC = lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         "pages/ProfilePage/innerPages/ActivityLog" /* webpackChunkName: "ActivityLogPage" */
//       )
//   )
// );
const DocumentsPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/ProfilePage/innerPages/Documents" /* webpackChunkName: "DocumentsPage" */
      )
  )
);
// const ReferralsPage: FC = lazy(() =>
//   // @ts-ignore
//   lazyRetry(
//     () =>
//       import(
//         "pages/ProfilePage/innerPages/Referrals" /* webpackChunkName: "ReferralsPage" */
//       )
//   )
// );
const RefferalPage: FC = lazy(() =>
  lazyRetry(
    () => import("pages/RefferalPage" /* webpackChunkName: "RefferalPage" */)
  )
);

const AdminRedirectPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/AdminRedirectPage" /* webpackChunkName: "AdminRedirectPage" */
      )
  )
);

const CryptoCardsPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import("pages/CryptoCardsPage" /* webpackChunkName: "CryptoCardsPage" */)
  )
);
const CryptoCardsDashboardPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/CryptoCardsPage/innerPages/CryptoCardsDashboard" /* webpackChunkName: "CryptoCardsDashboardPage" */
      )
  )
);
const CryptoCardsProductsPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/CryptoCardsPage/innerPages/CryptoCardsProducts" /* webpackChunkName: "CryptoCardsProductsPage" */
      )
  )
);
const CryptoCardPrurchasePage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/CryptoCardsPage/innerPages/CryptoCardPurchase" /* webpackChunkName: "CryptoCardPrurchasePage" */
      )
  )
);
const UserCryptoCardPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/CryptoCardsPage/innerPages/UsersCryptoCard" /* webpackChunkName: "UserCryptoCardPage" */
      )
  )
);

// const GiftCardsPage: FC = lazy(() =>
//   lazyRetry(
//     () => import("pages/GiftCardsPage" /* webpackChunkName: "GiftCardsPage" */)
//   )
// );
// const GiftCardsDashboard: FC = lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         "pages/GiftCardsPage/innerPages/GiftCardsDashboard" /* webpackChunkName: "GiftCardsDashboard" */
//       )
//   )
// );
// const GiftCardsProductsPage: FC = lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         "pages/GiftCardsPage/innerPages/GiftCardsProducts" /* webpackChunkName: "GiftCardsProductsPage" */
//       )
//   )
// );
// const GiftCardPurchasePage: FC = lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         "pages/GiftCardsPage/innerPages/GiftCardPurchase" /* webpackChunkName: "GiftCardPurchasePage" */
//       )
//   )
// );
// const UserGiftCardPage: FC = lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         "pages/GiftCardsPage/innerPages/UsersGiftCard" /* webpackChunkName: "UserGiftCardPage" */
//       )
//   )
// );

// const CryptoTransfersPage: FC = lazy(() =>
//   // @ts-ignore
//   lazyRetry(
//     () =>
//       import(
//         "pages/CryptoTransfersPage" /* webpackChunkName: "CryptoTransfersPage" */
//       )
//   )
// );
// const BuyCryptoPage: FC = lazy(() =>
//   // @ts-ignore
//   lazyRetry(
//     () =>
//       import(
//         "pages/CryptoTransfersPage/innerPages/BuyCrypto" /* webpackChunkName: "BuyCryptoPage" */
//       )
//   )
// );
// const SellCryptoPage: FC = lazy(() =>
//   // @ts-ignore
//   lazyRetry(
//     () =>
//       import(
//         "pages/CryptoTransfersPage/innerPages/SellCrypto" /* webpackChunkName: "BuyCryptoPage" */
//       )
//   )
// );
// const CryptoWidgetPage: FC = lazy(() =>
//   // @ts-ignore
//   lazyRetry(
//     () =>
//       import(
//         "pages/CryptoTransfersPage/innerPages/CryptoWidget" /* webpackChunkName: "CryptoWidgetPage" */
//       )
//   )
// );

const DocumentFormPage: FC = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/DocumentFormPage" /* webpackChunkName: "DocumentFormPage" */
      )
  )
);

const WebRoutes: FC = () => {
  const { isAuthenticated } = UserStore;

  return (
    <>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route
            path={routesPaths.resetPassword}
            element={<ResetPasswordPage />}
          />
          <Route path={routesPaths.verifyEmail} element={<VerifyEmailPage />} />
          <Route
            path={routesPaths.emailConfirm}
            element={<EmailConfirmPage />}
          />
          <Route
            path={routesPaths.adminRedirect}
            element={<AdminRedirectPage />}
          />

          <Route element={<PrivateRoute isAuth={isAuthenticated} />}>
            <Route path={routesPaths.dashboard} element={<DashbaordPage />} />
            <Route path={routesPaths.trade} element={<TradePage />}>
              <Route path={routesPaths.trade} element={<NewOrderPage />} />
              <Route
                path={routesPaths.openOrders}
                element={<OpenOrdersPage />}
              />
              <Route
                path={routesPaths.closedOrders}
                element={<OrdersHistoryPage />}
              />
              <Route
                path={`${routesPaths.trade}/*`}
                element={<Navigate to={routesPaths.trade} replace />}
              />
            </Route>
            {/* <Route path={routesPaths.earning} element={<EarningPage />}>
              <Route path={routesPaths.earning} element={<StakingPage />} />
              {/* {process.env.REACT_APP_ENVIRONMENT === "dev" && (
                <>
                  <Route
                    path={routesPaths.liquidityPool}
                    element={<GoldenMillionPage />}
                  />
                  <Route
                    path={routesPaths.copyTrading}
                    element={<DashboardCopyTrading />}
                  />
                  <Route
                    path={routesPaths.copyTradingStake}
                    element={<CopyTradingPage />}
                  />
                  DashboardCopyTrading
                </>
              )} 
              <Route
                path={`${routesPaths.earning}/*`}
                element={<Navigate to={routesPaths.earning} replace />}
              />
            </Route> */}
            <Route path={routesPaths.wallet} element={<WalletPage />} />
            <Route path={routesPaths.history} element={<HistoryPage />} />
            {/* <Route path={routesPaths.futures} element={<FuturesPage />}>
              <Route
                path={routesPaths.futures}
                element={<FuturesTradePage />}
              />
              <Route
                path={routesPaths.futuresBalances}
                element={<FuturesBalancesPage />}
              />
              <Route
                path={`${routesPaths.futures}/*`}
                element={<Navigate to={routesPaths.futures} replace />}
              />
            </Route> */}
            <Route path={routesPaths.profile} element={<ProfilePage />}>
              <Route
                path={routesPaths.profile}
                element={<ProfileSettingsPage />}
              />
              <Route
                path={routesPaths.verification}
                element={<VerificationPage />}
              />
              <Route path={routesPaths.documents} element={<DocumentsPage />} />

              {/* <Route path={routesPaths.referrals} element={<ReferralsPage />} /> */}
              {/* {process.env.REACT_APP_ENVIRONMENT === "dev" && (
                <Route
                  path={routesPaths.activityLog}
                  element={<ActivityLogPage />}
                />
              )} */}
              <Route
                path={`${routesPaths.profile}/*`}
                element={<Navigate to={routesPaths.profile} replace />}
              />
            </Route>
            {/* <Route path={routesPaths.games} element={<GamesPage />} /> */}

            <Route path={routesPaths.cryptoCards} element={<CryptoCardsPage />}>
              <Route
                path={routesPaths.cryptoCards}
                element={<CryptoCardsDashboardPage />}
              />

              <Route
                path={routesPaths.cryptoCardsProducts}
                element={<CryptoCardsProductsPage />}
              />
              <Route
                path={routesPaths.cryptoCardPhysicalProductFull}
                element={<CryptoCardPrurchasePage />}
              />
              <Route
                path={routesPaths.cryptoCardPurcahseFull}
                element={<CryptoCardPrurchasePage />}
              />
              <Route
                path={routesPaths.usersCryptoCardFull}
                element={<UserCryptoCardPage />}
              />

              <Route
                path={`${routesPaths.cryptoCards}/*`}
                element={<Navigate to={routesPaths.cryptoCards} replace />}
              />
            </Route>

            {/* <Route path={routesPaths.giftCards} element={<GiftCardsPage />}>
              <Route
                path={routesPaths.giftCards}
                element={<GiftCardsDashboard />}
              />
              <Route
                path={routesPaths.giftCardsProducts}
                element={<GiftCardsProductsPage />}
              />
              <Route
                path={routesPaths.giftCardPurchaseFull}
                element={<GiftCardPurchasePage />}
              />
              <Route
                path={routesPaths.usersGiftCardFull}
                element={<UserGiftCardPage />}
              />

              <Route
                path={`${routesPaths.giftCards}/*`}
                element={<Navigate to={routesPaths.giftCards} replace />}
              />
            </Route> */}

            {/* <Route
              path={routesPaths.cryptoTransfers}
              element={<CryptoTransfersPage />}
            >
              <Route path={routesPaths.buyCrypto} element={<BuyCryptoPage />} />
              <Route
                path={routesPaths.sellCrypto}
                element={<SellCryptoPage />}
              />
              <Route
                path={routesPaths.cryptoWidget}
                element={<CryptoWidgetPage />}
              />

              <Route
                path={`${routesPaths.cryptoTransfers}/*`}
                element={<Navigate to={routesPaths.buyCrypto} replace />}
              />
              <Route
                path={routesPaths.cryptoTransfers}
                element={<Navigate to={routesPaths.buyCrypto} replace />}
              />
            </Route> */}
            <Route
              path={routesPaths.documentForm}
              element={<DocumentFormPage />}
            />
          </Route>
          <Route element={<PublicRoute isAuth={isAuthenticated} />}>
            <Route path={routesPaths.login} element={<LoginPage />} />
            {/* <Route path={routesPaths.signUp} element={<SignUpPage />} /> */}
            <Route path={routesPaths.referral} element={<RefferalPage />} />

            <Route
              path={routesPaths.forgotPassword}
              element={<ForgotPasswordPage />}
            />
          </Route>
          <Route
            path="*"
            element={
              <Navigate
                to={isAuthenticated ? routesPaths.dashboard : routesPaths.login}
                replace
              />
            }
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default observer(WebRoutes);
