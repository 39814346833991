import { FC, useCallback, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  CloseModalButton,
  BackModalButton,
  MainButton,
  ModalButton,
} from "components/Buttons";
import BasicModal from "./BasicModal";
import { TextInput } from "components/Inputs";
import { ModalHeading, ModalContent, InputContainer } from "./styled";
import { Form } from "pages/LoginPage/styled";
import { colors, notVerifiedMessage, twoFAMessage } from "helpers/consts";
import { AppText } from "components";
import { telegramLinkAccount, initiateVerifyEmail } from "api/user";
import { GlobalStore, UserStore } from "stores";
import type { LoginCredentials } from "api/types/user";

import { ReactComponent as LockIcon } from "assets/icons/lock-unlocked.svg";

interface InitialErrors {
  email?: string;
  password?: string;
}

interface LinkAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const LinkAccountModal: FC<LinkAccountModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const {
    user: { email },
  } = UserStore;

  const [twoFAIsRequired, setTwoFAIsRequired] = useState<boolean>(false);
  const [emailForVerify, setEmailForVerify] = useState<string>("");
  const [twoFACode, setTwoFACode] = useState<string>("");
  const [timerCount, setTimer] = useState<number>(0);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    setSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const loginData: LoginCredentials = {
        email: values.email,
        password: values.password,
      };

      const respMessage = await telegramLinkAccount(loginData);

      if (respMessage === notVerifiedMessage) {
        setEmailForVerify(values.email);
      }

      if (respMessage === twoFAMessage) {
        setTwoFAIsRequired(true);
      }

      setSubmitting(false);
    },
    validate: (values) => {
      const errors: InitialErrors = {};
      if (!values.email) {
        errors.email = "EMAIL_REQUIRED";
      }
      if (!values.password) {
        errors.password = "PASSWORD_REQUIRED";
      }
      return errors;
    },
  });

  const sendVerifyLink = useCallback(async () => {
    setSubmitting(true);
    await initiateVerifyEmail({ email: emailForVerify })
      .then(() => setTimer(60))
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      });
    setSubmitting(false);
  }, [emailForVerify, setSubmitting]);

  const login2FA = useCallback(async () => {
    setSubmitting(true);
    const loginData: LoginCredentials = {
      email: values.email,
      password: values.password,
      oneTimePassword: twoFACode,
    };

    await telegramLinkAccount(loginData);
    setSubmitting(false);
  }, [values, setSubmitting, twoFACode]);

  const on2FABack = useCallback(async () => {
    setTwoFACode("");
    setTwoFAIsRequired(false);
  }, []);

  useEffect(() => {
    if (twoFACode.length === 6) {
      login2FA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFACode]);

  const resetState = useCallback(() => {
    resetForm();
    setTwoFACode("");
  }, [resetForm]);

  useEffect(() => {
    if (!email.includes("telegram")) {
      onClose();
    }
  }, [email, onClose]);

  return (
    <BasicModal onExited={resetState} isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <BackModalButton onBack={onClose} label="back" />
        <CloseModalButton onClose={onClose} label="close" />

        <ModalHeading $centered>{t("LINK_WEB_ACCOUNT")}</ModalHeading>

        <Form onSubmit={handleSubmit}>
          {twoFAIsRequired ? (
            <>
              <InputContainer>
                <AppText>{t("2FA Code")}</AppText>
                <TextInput
                  placeholder={t("CODE")}
                  value={twoFACode}
                  onChange={({ target: { value } }) => setTwoFACode(value)}
                  leftIcon={<LockIcon />}
                  autoComplete="off"
                  name="2fa-code"
                />
              </InputContainer>

              <MainButton
                color="secondary"
                fullWidth
                fontSize={16}
                onClick={on2FABack}
                style={{ marginTop: "1.6rem" }}
              >
                {t("BACK")}
              </MainButton>
            </>
          ) : (
            <>
              <InputContainer>
                <AppText>{t("EMAIL")}</AppText>
                <TextInput
                  name="email"
                  placeholder={t("ENTER_EMAIL")}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email && t(errors.email)}
                />
              </InputContainer>
              <InputContainer>
                <AppText>{t("PASSWORD")}</AppText>
                <TextInput
                  type="password"
                  name="password"
                  placeholder={t("ENTER_PASSWORD")}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={
                    touched.password && errors.password && t(errors.password)
                  }
                />
              </InputContainer>
              {timerCount > 0 && (
                <AppText fontWeight={400} style={{ marginBottom: "1.6rem" }}>
                  {t("RESEND_IN")}{" "}
                  <AppText
                    isSpan
                    fontWeight={600}
                    style={{ color: colors.primary_600 }}
                  >
                    {timerCount}
                  </AppText>
                </AppText>
              )}
              {emailForVerify && (
                <MainButton
                  variant="contained"
                  fullWidth
                  fontSize={16}
                  style={{ marginBottom: "1.6rem" }}
                  onClick={() => sendVerifyLink()}
                  disabled={timerCount > 0 || isSubmitting}
                >
                  {t("RESEND_EMAIL")}
                </MainButton>
              )}

              <ModalButton
                variant="contained"
                fullWidth
                type="submit"
                disabled={isSubmitting || !!errors.email || !!errors.password}
              >
                {t("LOGIN")}
              </ModalButton>
            </>
          )}
        </Form>
      </ModalContent>
    </BasicModal>
  );
};

export default observer(LinkAccountModal);
