import { FC, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import {
  ModalButton,
  BackModalButton,
  CloseModalButton,
} from "components/Buttons";
import OrderInfoRows from "./OrderInfoRows";
import { AppText, PairCoins } from "components";
import { normolizeDecimalsWithNoCode } from "helpers/funcs";
import {
  ModalContent,
  ModalHeading,
  InnerContentForm,
  ConfirmBlock,
} from "../styled";
import type { CurrencyInterface, ByBitInstrument } from "helpers/types";

interface ConfirmOrderStepProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  activeInstrument: ByBitInstrument | null;
  onClose: () => void;
  leftCurrencyValue: string;
  rightCurrencyValue: string;
  tradeType: number;
  cost: number;
  currencies: CurrencyInterface[] | null;
  isLoading: boolean;
  operation: number;
}

const ConfirmOrderStep: FC<ConfirmOrderStepProps> = ({
  onSubmit,
  activeInstrument,
  onClose,
  leftCurrencyValue,
  rightCurrencyValue,
  tradeType,
  cost,
  currencies,
  isLoading,
  operation,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <CloseModalButton onClose={onClose} />

        <BackModalButton onBack={onClose} />

        <ModalHeading $centered>{t("TRADE_REVIEW")}</ModalHeading>
        <InnerContentForm onSubmit={(e) => onSubmit(e)}>
          <ConfirmBlock>
            <PairCoins
              from={activeInstrument!.baseCoin}
              to={activeInstrument!.quoteCoin}
              forModal
            />
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_700}
              lineHeight={30}
              style={{ marginTop: "0.4rem" }}
            >
              {activeInstrument!.baseCoin}/{activeInstrument!.quoteCoin}
            </AppText>
            <AppText
              fontSize={16}
              style={{ textTransform: "capitalize" }}
              fontWeight={400}
              color={colors.gray_700}
            >
              {operation === 0 ? "Limit" : "Market"} {t("ORDER")}{" "}
              {tradeType === 0 ? t("PURCHASE") : t("SALE")}
            </AppText>
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_700}
              lineHeight={30}
            >
              {tradeType === 1
                ? normolizeDecimalsWithNoCode(
                    Number(leftCurrencyValue),
                    activeInstrument!.baseCoin,
                    currencies
                  )
                : normolizeDecimalsWithNoCode(
                    Number(rightCurrencyValue),
                    activeInstrument!.baseCoin,
                    currencies
                  )}
              <AppText
                isSpan
                fontSize={20}
                fontWeight={600}
                color={colors.gray_400}
                lineHeight={30}
              >
                {` ${activeInstrument!.baseCoin}`}
              </AppText>
            </AppText>
          </ConfirmBlock>

          <OrderInfoRows
            leftCurrencyValue={leftCurrencyValue}
            rightCurrencyValue={rightCurrencyValue}
            activeInstrument={activeInstrument}
            tradeType={tradeType}
            cost={cost}
            currencies={currencies}
          />

          <ModalButton type="submit" disabled={isLoading}>
            {t("CONFIRM")}
          </ModalButton>
        </InnerContentForm>
      </ModalContent>
    </Fade>
  );
};

export default ConfirmOrderStep;
