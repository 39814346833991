import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
// import { MetaMaskProvider } from "@metamask/sdk-react";

import App from "./App";
// import { siteName } from "helpers/consts";
import "simplebar-react/dist/simplebar.min.css";
import "api/apiConfig";

polyfillCountryFlagEmojis();

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    {/* <MetaMaskProvider
      debug={false}
      sdkOptions={{
        dappMetadata: {
          name: siteName,
          url: window.location.origin,
        },
      }}
    > */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </MetaMaskProvider> */}
  </LocalizationProvider>
);
