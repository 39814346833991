import { makeAutoObservable } from "mobx";
import type {
  ByBitBalanceItem,
  ByBitDepositWithdrawAssetsFull,
  ByBitGetTickerData,
  ByBitInstrument,
} from "helpers/types";
import type { LibrarySymbolInfo } from "helpers/scripts/charting_library";

class Store {
  activeInstrument: ByBitInstrument | null = null;
  instrumentsList: ByBitInstrument[] | null = null;
  symbolInfo: LibrarySymbolInfo | null = null;
  tickerData: ByBitGetTickerData | null = null;
  balance: number = 0;
  balancesList: ByBitBalanceItem[] | null = null;
  availableDeposits: ByBitDepositWithdrawAssetsFull[] | null = null;
  availableWithdraws: ByBitDepositWithdrawAssetsFull[] | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setBalance = (value: number) => {
    this.balance = value;
  };

  setBalancesList = (wallets: ByBitBalanceItem[] | null) => {
    this.balancesList = wallets;
  };

  setAvailableDeposits = (
    deposits: ByBitDepositWithdrawAssetsFull[] | null
  ) => {
    this.availableDeposits = deposits;
  };

  setAvailableWithdraws = (
    withdraws: ByBitDepositWithdrawAssetsFull[] | null
  ) => {
    this.availableWithdraws = withdraws;
  };

  setSymbolInfo = (symbolInfo: LibrarySymbolInfo | null) => {
    this.symbolInfo = symbolInfo;
  };

  setTickerData = (tickerData: ByBitGetTickerData | null) => {
    this.tickerData = tickerData;
  };

  setInstrumentsList = (instrumentsList: ByBitInstrument[] | null) => {
    this.instrumentsList = instrumentsList;
  };

  setActiveInstrument = (activeInstrument: ByBitInstrument | null) => {
    this.activeInstrument = activeInstrument;
  };
}

const byBitStore = new Store();
export default byBitStore;
