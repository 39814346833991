import {
  ChangeEventHandler,
  FC,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { OutlinedInput, Button, TextFieldProps } from "@mui/material";
import { TextInputProps } from "components/Inputs/types";
import { colors } from "helpers/consts";
import { manualNumInputValidator } from "helpers/regex";
import { normolizeDecimalsForInputs } from "helpers/funcs";
import { AppText } from "components";
import type { MarketsInterface, CurrencyInterface } from "helpers/types";

interface CurrencyInputProps extends TextInputProps {
  onClick?: () => void;
  setLimitPriceValue: Dispatch<SetStateAction<string>>;
  currentExchangeMarket?: MarketsInterface | null;
  isLimitPriceCorrect?: boolean;
  currencies?: CurrencyInterface[] | null;
  isByBit?: boolean;
  isThin?: boolean;
  darkTheme?: boolean;
}

const LimitInput: FC<CurrencyInputProps> = ({
  value,
  setLimitPriceValue,
  currentExchangeMarket,
  isLimitPriceCorrect = true,
  currencies,
  isByBit,
  isThin,
  darkTheme,
  disabled,
}) => {
  const { t } = useTranslation();
  const onLimitChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    const { value } = ev.target;

    if (isByBit) {
      setLimitPriceValue(value);
      return;
    }
    const canSetNewValue = currentExchangeMarket?.ohlcv?.pairDecimals
      ? manualNumInputValidator(
          currentExchangeMarket?.ohlcv?.pairDecimals
        ).test(value) || !value.length
      : manualNumInputValidator(8).test(value) || !value.length;

    if (canSetNewValue) {
      setLimitPriceValue(value);
    }
  };

  const onLimitAutoSet = useCallback(
    (value: string) => {
      const decimalsRestriction = currentExchangeMarket?.ohlcv?.pairDecimals;
      if (decimalsRestriction) {
        setLimitPriceValue(Number(value).toFixed(decimalsRestriction));
        return;
      }
      setLimitPriceValue(value);
    },
    [currentExchangeMarket, setLimitPriceValue]
  );

  return (
    <>
      <StyledTextField
        value={value}
        error={!isLimitPriceCorrect}
        onChange={onLimitChange}
        disabled={disabled}
        endAdornment={
          currentExchangeMarket?.ohlcv?.close && (
            <SliderButtons>
              <SliderButton
                onClick={() => onLimitAutoSet(currentExchangeMarket?.ohlcv.ask)}
              >
                Ask
              </SliderButton>
              <SliderButton
                onClick={() => onLimitAutoSet(currentExchangeMarket?.ohlcv.bid)}
              >
                Bid
              </SliderButton>
              <SliderButton
                onClick={() =>
                  onLimitAutoSet(currentExchangeMarket?.ohlcv?.close)
                }
              >
                Last
              </SliderButton>
            </SliderButtons>
          )
        }
        $isThin={isThin}
        $darkTheme={darkTheme}
        inputProps={{
          inputMode: "decimal",
          lang: "en-US",
          pattern: "[0-9]*([.][0-9]*?)?",
        }}
        fullWidth
        placeholder={
          isByBit
            ? "0"
            : `${normolizeDecimalsForInputs(
                Number(currentExchangeMarket?.ohlcv?.close) -
                  (Number(currentExchangeMarket?.ohlcv?.close) * 10) / 100,
                currentExchangeMarket?.leftCurrencyName || "USDT",
                currencies
              )} - ${normolizeDecimalsForInputs(
                Number(currentExchangeMarket?.ohlcv?.close) +
                  (Number(currentExchangeMarket?.ohlcv?.close) * 10) / 100,
                currentExchangeMarket?.leftCurrencyName || "USDT",
                currencies
              )}`
        }
      />
      {!isLimitPriceCorrect && !isByBit && (
        <AppText color={colors.error_500} fontSize={11}>
          {t("LIMIT_ERROR")}
        </AppText>
      )}
    </>
  );
};

const SliderButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background-color: transparent;
  padding-right: 1rem;

  & > *:not(:first-child) {
    margin-left: 0.8rem;
  }
`;

const SliderButton = styled(Button)`
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
  min-height: 3.6rem;
  max-height: 3.6rem;
  text-transform: none;
  font-weight: 600;
  font-size: 1.2rem;
  background-color: transparent;
  min-width: 0;
  border: 1px solid ${colors.gray_200};
  color: ${colors.gray_700};
  max-width: max-content;

  @media (min-width: 28em) {
    min-width: 5.4rem;
    padding: 0.8rem 1.4rem;
    font-size: 1.4rem;
  }
`;

interface LimitTextFieldInterface {
  $isThin?: boolean;
  $darkTheme?: boolean;
}
const StyledTextField = styled(OutlinedInput)<
  TextFieldProps & LimitTextFieldInterface
>`
  background-color: ${colors.white};
  border-radius: 0.8rem;

  &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline,
  &:focus-within .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: ${colors.primary_500};
  }
  .MuiOutlinedInput-notchedOutline {
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.error_500};
  }
  &.Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: ${colors.error_500};
  }

  .MuiInputBase-input {
    padding: ${({ $isThin }) =>
      $isThin
        ? "1.05rem 0.6rem 1.05rem 1.2rem"
        : "1.6rem 0.6rem 1.6rem 1.2rem"};
    font-size: 1.6rem;
    font-weight: 500;
    color: ${({ $darkTheme }) =>
      $darkTheme ? colors.gray_100 : colors.gray_700};
    border-radius: 0.8rem;
    background-color: ${({ $darkTheme }) =>
      $darkTheme ? colors.gray_700 : colors.white};
    transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:focus {
      color: ${colors.primary_600};
    }
    &:disabled {
      color: ${({ $darkTheme }) =>
        $darkTheme ? colors.white : colors.gray_500};
      background-color: ${({ $darkTheme }) =>
        $darkTheme ? colors.gray_500 : colors.gray_50};
      -webkit-text-fill-color: ${({ $darkTheme }) =>
        $darkTheme ? colors.gray_50 : colors.gray_500};
    }
    &:disabled + fieldset {
      border: 1px solid
        ${({ $darkTheme }) => ($darkTheme ? colors.gray_500 : colors.gray_100)};
    }
  }
  .MuiInputBase-root {
    border-radius: 0.8rem;
    ${({ disabled }) => disabled && `background-color: ${colors.gray_50};`}
  }
  fieldset {
    border: 1px solid
      ${({ $darkTheme }) => ($darkTheme ? colors.gray_400 : colors.gray_100)};
    border-radius: 0.8rem;
  }
  .MuiInputAdornment-root {
    height: 100%;
    margin-left: 0;
    max-height: ${({ $isThin }) => ($isThin ? "4.4rem" : "5.6rem")};
    min-height: ${({ $isThin }) => ($isThin ? "4.4rem" : "5.6rem")};
  }
`;

export default LimitInput;
