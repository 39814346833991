import {
  FC,
  useEffect,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
  useCallback,
  FormEvent,
} from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Preloader } from "components";
import BasicModal from "../BasicModal";
import MainTradeStep from "./MainTradeStep";
import ConfirmTradeStep from "./ConfirmTradeStep";
import SuccessTradeStep from "./SuccessTradeStep";
import { trade } from "api/exchange";
import { normolizeCurrenciesDecimals } from "helpers/funcs";
import { CurrenciesStore, GlobalStore } from "stores";
import type {
  ModalStepsType,
  AvailableTradeOptions,
  MarketsInterface,
} from "helpers/types";
import type { TradeInterface } from "api/types/exchange";

export type OperationType = "buy" | "sell";
export type MarketsType = "market" | "limit";
interface TradeModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialCoin?: AvailableTradeOptions;
  isStatic?: boolean;
  staticModalOpen?: () => void;
  currentMarket?: MarketsInterface | null;
  setCurrentMarket?: Dispatch<SetStateAction<MarketsInterface | null>>;
  extraCallback?: () => void;
}
const TradeModal: FC<TradeModalProps> = ({
  isOpen,
  onClose,
  initialCoin,
  isStatic,
  staticModalOpen,
  currentMarket,
  setCurrentMarket,
  extraCallback,
}) => {
  const { t } = useTranslation();
  const { markets, currencies, prices } = CurrenciesStore;
  const [step, setStep] = useState<ModalStepsType>("main");
  const [skipConfirm, setSkipConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMaxValue, setIsMaxValue] = useState<boolean>(false);
  const [leftCurrencyValue, setLeftCurrencyValue] = useState<string>("");
  const [fullLeftCurrencyValue, setFullLeftCurrencyValue] =
    useState<string>("");
  const [limitPriceValue, setLimitPriceValue] = useState<string>("");
  const [leftCurrency, setLeftCurrency] =
    useState<AvailableTradeOptions | null>(null);
  const [rightCurrencyValue, setRightCurrencyValue] = useState<string>("");
  const [rightCurrency, setRightCurrency] =
    useState<AvailableTradeOptions | null>(null);
  const [operationType, setOperationType] = useState<OperationType>("sell");
  const [tabValue, setTabValue] = useState<number>(0);
  const [currentFee, setCurrentFee] = useState<number>(0);

  const currentExchangeMarket = useMemo<MarketsInterface | null>(() => {
    const exchangeMarket = markets?.find(
      ({ leftCurrencyName, rightCurrencyName }) =>
        operationType === "sell"
          ? leftCurrencyName === leftCurrency &&
            rightCurrencyName === rightCurrency
          : rightCurrencyName === leftCurrency &&
            leftCurrencyName === rightCurrency
    );
    if (!exchangeMarket) {
      return null;
    }
    const currentPrices =
      prices?.[
        operationType === "sell"
          ? `${leftCurrency}/${rightCurrency}`
          : `${rightCurrency}/${leftCurrency}`
      ];

    if (!currentPrices) {
      return exchangeMarket;
    }

    const exchangeMarketWithCurrenctPrices = { ...exchangeMarket };
    exchangeMarketWithCurrenctPrices.ohlcv = currentPrices;

    return exchangeMarketWithCurrenctPrices;
  }, [markets, leftCurrency, rightCurrency, operationType, prices]);

  const marketType = useMemo<MarketsType>(
    () => (tabValue === 0 ? "market" : "limit"),
    [tabValue]
  );

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!markets || markets.length === 0) {
      return;
    }
    if (step === "main" && !skipConfirm) {
      if (staticModalOpen && isStatic) {
        staticModalOpen();
      }
      setStep("confirm");

      return;
    }

    const exchangeData: TradeInterface = {
      marketId: currentExchangeMarket!.id,
      volume: Number(
        operationType === "sell"
          ? Number(
              normolizeCurrenciesDecimals(
                fullLeftCurrencyValue,
                leftCurrency!,
                currencies
              )
            )
          : rightCurrencyValue
      ),
      orderSide: operationType,
      orderType: marketType,
    };
    if (marketType === "limit") {
      exchangeData.price = Number(limitPriceValue);
    }
    if (isMaxValue) {
      exchangeData.isMaxVolume = true;
    }

    setIsLoading(true);

    trade(exchangeData)
      .then(() => {
        if (!!staticModalOpen && isStatic) {
          staticModalOpen();
        }
        if (!!extraCallback) {
          extraCallback();
        }
        setStep("success");
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!markets || isStatic || !isOpen) {
      return;
    }

    if (initialCoin) {
      const initialMarket = markets?.find(
        ({ leftCurrencyName }) => leftCurrencyName === initialCoin
      );
      if (initialMarket) {
        setLeftCurrency(initialMarket.leftCurrencyName);
        setRightCurrency(initialMarket.rightCurrencyName);
        return;
      }
      const initialBuyMarket = markets?.find(
        ({ rightCurrencyName }) => rightCurrencyName === initialCoin
      );
      if (initialBuyMarket) {
        setLeftCurrency(initialBuyMarket.rightCurrencyName);
        setRightCurrency(initialBuyMarket.leftCurrencyName);
        setOperationType("buy");
        return;
      }
    }
    setLeftCurrency(markets[0].leftCurrencyName);
    setRightCurrency(markets[0].rightCurrencyName);
  }, [initialCoin, markets, isOpen, isStatic]);

  useEffect(() => {
    if (!markets || !isStatic || isOpen) {
      return;
    }

    if (
      currentExchangeMarket &&
      ((currentExchangeMarket.leftCurrencyName === leftCurrency &&
        currentExchangeMarket.rightCurrencyName === rightCurrency) ||
        (currentExchangeMarket.leftCurrencyName === rightCurrency &&
          currentExchangeMarket.rightCurrencyName === leftCurrency))
    ) {
      return;
    }
    if (
      currentExchangeMarket &&
      currentExchangeMarket.leftCurrencyName === rightCurrency
    ) {
      setLeftCurrency(rightCurrency);
      setRightCurrency(leftCurrency);
      return;
    }
    const staticExchangeMarket = markets?.find(
      ({ leftCurrencyName, rightCurrencyName }) =>
        leftCurrencyName === rightCurrency && rightCurrencyName === leftCurrency
    );

    if (staticExchangeMarket) {
      setLeftCurrency(rightCurrency);
      setRightCurrency(leftCurrency);
      return;
    }
    const usdtMarket = markets?.find(
      ({ leftCurrencyName, rightCurrencyName }) =>
        leftCurrencyName === "ETH" && rightCurrencyName === "USDT"
    );
    if (usdtMarket) {
      setLeftCurrency(usdtMarket.leftCurrencyName);
      setRightCurrency(usdtMarket.rightCurrencyName);
      return;
    }
    setLeftCurrency(markets[0].leftCurrencyName);
    setRightCurrency(markets[0].rightCurrencyName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markets, isOpen]);

  // useEffect(() => {
  //   if (
  //     (limitPriceValue === "" || !limitPriceValue) &&
  //     marketType === "limit"
  //   ) {
  //     if (!currentExchangeMarket?.ohlcv?.close) {
  //       return;
  //     }
  //     const decimalsRestriction = currentExchangeMarket?.ohlcv?.pairDecimals;

  //     if (decimalsRestriction) {
  //       setLimitPriceValue(
  //         Number(currentExchangeMarket.ohlcv.close).toFixed(decimalsRestriction)
  //       );
  //       return;
  //     }
  //     setLimitPriceValue(currentExchangeMarket.ohlcv.close);
  //   }
  // }, [currentExchangeMarket, marketType, limitPriceValue]);

  useEffect(() => {
    if (
      marketType === "market" &&
      markets &&
      currentExchangeMarket &&
      currentExchangeMarket.marketOrderEnabled === false
    ) {
      const availableLimitMarket = markets.find(
        ({ marketOrderEnabled }) => marketOrderEnabled === true
      );
      if (!availableLimitMarket) {
        return;
      }
      setLeftCurrency(availableLimitMarket.leftCurrencyName);
      setRightCurrency(availableLimitMarket.rightCurrencyName);
      return;
    }

    if (
      marketType === "limit" &&
      markets &&
      currentExchangeMarket &&
      currentExchangeMarket.limitOrderEnabled === false
    ) {
      const availableLimitMarket = markets.find(
        ({ limitOrderEnabled }) => limitOrderEnabled === true
      );
      if (!availableLimitMarket) {
        return;
      }
      setLeftCurrency(availableLimitMarket.leftCurrencyName);
      setRightCurrency(availableLimitMarket.rightCurrencyName);

      const decimalsRestriction = currentExchangeMarket?.ohlcv?.pairDecimals;

      if (decimalsRestriction) {
        setLimitPriceValue(
          Number(currentExchangeMarket.ohlcv.close).toFixed(decimalsRestriction)
        );
        return;
      }
      setLimitPriceValue(availableLimitMarket.ohlcv.close);
    }
  }, [currentExchangeMarket, marketType, limitPriceValue, markets]);

  const mainCostValue = useMemo<number>(() => {
    if (marketType === "limit") {
      return Number(limitPriceValue) || 0;
    }
    const mainCost =
      operationType === "sell"
        ? currentExchangeMarket?.ohlcv?.bid
        : currentExchangeMarket?.ohlcv?.ask;
    return Number(mainCost) || 0;
  }, [currentExchangeMarket, marketType, limitPriceValue, operationType]);

  const fees = useMemo<number | 0>(() => {
    if (!markets || !currentExchangeMarket) {
      return 0;
    }

    const exchangeFees = currentExchangeMarket.fees?.makerFee;

    return exchangeFees ? exchangeFees : 0;
  }, [markets, currentExchangeMarket]);

  useEffect(() => {
    if (
      !!setCurrentMarket &&
      currentExchangeMarket &&
      currentExchangeMarket.name !== currentMarket?.name
    ) {
      setCurrentMarket(currentExchangeMarket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentMarket, currentExchangeMarket]);

  const closeModal = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const resetState = useCallback(() => {
    if (!isStatic) {
      setOperationType("sell");
    }
    if (isStatic && step === "confirm") {
      setStep("main");
      return;
    }
    setStep("main");
    setLeftCurrencyValue("");
    setFullLeftCurrencyValue("");
    setRightCurrencyValue("");
    setLimitPriceValue("");
    setCurrentFee(0);
  }, [isStatic, step]);

  return (
    <>
      {isStatic && (
        <MainTradeStep
          onSubmit={onSubmit}
          skipConfirm={skipConfirm}
          setSkipConfirm={setSkipConfirm}
          leftCurrencyValue={leftCurrencyValue}
          rightCurrencyValue={rightCurrencyValue}
          leftCurrency={leftCurrency}
          rightCurrency={rightCurrency}
          setLeftCurrencyValue={setLeftCurrencyValue}
          setRightCurrencyValue={setRightCurrencyValue}
          setLeftCurrency={setLeftCurrency}
          setRightCurrency={setRightCurrency}
          onClose={closeModal}
          operationType={operationType}
          setOperationType={setOperationType}
          tabValue={tabValue}
          setTabValue={setTabValue}
          fees={fees}
          cost={mainCostValue}
          isLoading={isLoading}
          isOpen={isOpen}
          currentExchangeMarket={currentExchangeMarket}
          limitPriceValue={limitPriceValue}
          setLimitPriceValue={setLimitPriceValue}
          marketType={marketType}
          setFullLeftCurrencyValue={setFullLeftCurrencyValue}
          fullLeftCurrencyValue={fullLeftCurrencyValue}
          setIsMaxValue={setIsMaxValue}
          isMaxValue={isMaxValue}
          currentFee={currentFee}
          setCurrentFee={setCurrentFee}
          isStatic
        />
      )}
      <BasicModal
        isOpen={isOpen}
        onExited={resetState}
        onClose={closeModal}
        title={t("TRADE")}
      >
        <>
          {isLoading && (!isStatic || (isStatic && step === "confirm")) && (
            <Preloader isStatic />
          )}
          {!isStatic && step === "main" && (
            <MainTradeStep
              onSubmit={onSubmit}
              skipConfirm={skipConfirm}
              setSkipConfirm={setSkipConfirm}
              leftCurrencyValue={leftCurrencyValue}
              rightCurrencyValue={rightCurrencyValue}
              leftCurrency={leftCurrency}
              rightCurrency={rightCurrency}
              setLeftCurrencyValue={setLeftCurrencyValue}
              setRightCurrencyValue={setRightCurrencyValue}
              setLeftCurrency={setLeftCurrency}
              setRightCurrency={setRightCurrency}
              onClose={closeModal}
              operationType={operationType}
              setOperationType={setOperationType}
              tabValue={tabValue}
              setTabValue={setTabValue}
              fees={fees}
              cost={mainCostValue}
              isOpen={isOpen}
              currentExchangeMarket={currentExchangeMarket}
              isLoading={isLoading}
              limitPriceValue={limitPriceValue}
              setLimitPriceValue={setLimitPriceValue}
              marketType={marketType}
              setFullLeftCurrencyValue={setFullLeftCurrencyValue}
              fullLeftCurrencyValue={fullLeftCurrencyValue}
              setIsMaxValue={setIsMaxValue}
              currentFee={currentFee}
              setCurrentFee={setCurrentFee}
              isMaxValue={isMaxValue}
            />
          )}
          {step === "confirm" && (
            <ConfirmTradeStep
              onSubmit={onSubmit}
              skipConfirm={skipConfirm}
              setSkipConfirm={setSkipConfirm}
              leftCurrencyValue={leftCurrencyValue}
              rightCurrencyValue={rightCurrencyValue}
              leftCurrency={leftCurrency!}
              rightCurrency={rightCurrency!}
              onBack={() => setStep("main")}
              onClose={closeModal}
              operationType={operationType}
              marketType={marketType}
              cost={mainCostValue}
              currentExchangeMarket={currentExchangeMarket}
              currencies={currencies}
              isStatic={isStatic}
              isLoading={isLoading}
              currentFee={currentFee}
            />
          )}
          {step === "success" && (
            <SuccessTradeStep
              leftCurrencyValue={leftCurrencyValue}
              rightCurrencyValue={rightCurrencyValue}
              leftCurrency={leftCurrency!}
              rightCurrency={rightCurrency!}
              onClose={closeModal}
              operationType={operationType}
              marketType={marketType}
              cost={mainCostValue}
              currentExchangeMarket={currentExchangeMarket}
              currencies={currencies}
              currentFee={currentFee}
            />
          )}
        </>
      </BasicModal>
    </>
  );
};

export default observer(TradeModal);
