import styled from "styled-components";
import { colors } from "helpers/consts";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray_200};

  @media (min-width: 36em) {
    border-radius: 1.2rem;
  }

  @media (min-width: 48em) {
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: row;
  }
`;

interface ImageContainerInterface {
  isSmaller?: boolean;
  isRotated?: boolean;
}
export const ImageContainer = styled.div<ImageContainerInterface>`
  width: 100%;
  background-color: #0092DE;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-height: none;
  max-height: 15.4rem;
  padding: 0.6rem 0 0;
  overflow: hidden;

  & > img {
    max-height: 33rem;
    ${({ isRotated }) =>
      isRotated && "transform: rotate(30deg) rotateY(180deg);"}
  }
  @media (min-width: 48em) {
    align-items: center;
    width: 50%;
    padding: ${({ isSmaller }) => (isSmaller ? "3.2rem 0" : "6.4rem 0")};
    max-height: none;

    & > img {
      max-height: 20rem;
    }
  }
`;

export const MainSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 3.2rem 2.4rem;

  @media (min-width: 48em) {
    width: 50%;
    padding: 6.4rem 2.4rem;
  }
`;

interface InputContainerInterface {
  $noMargin?: boolean;
}
export const InputContainer = styled.div<InputContainerInterface>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ $noMargin }) => !$noMargin && "margin-bottom: 1.6rem;"}

  & > *:not(:first-child) {
    margin-top: 0.6rem;
  }
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 36rem;

  & > a {
    margin: 1.4rem 0;
    padding: 1rem 0;
  }

  & > .MuiFormControlLabel-root {
    position: relative;
    left: -1.2rem;
    & > .MuiTypography-body1 {
      margin-top: 1.2rem;
    }
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 36rem;

  & > a {
    margin: 1.4rem 0;
    padding: 1rem 0;
  }

  & > .MuiFormControlLabel-root {
    position: relative;
    left: -1.2rem;
    & > .MuiTypography-body1 {
      margin-top: 1.2rem;
    }
  }
`;

export const StyledDividerContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 2.4rem 0;

  & > p {
    text-transform: uppercase;
    max-width: max-content;
    position: relative;
    z-index: 2;
    padding: 0 0.8rem;
    background-color: ${colors.white};
  }
`;
export const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  z-index: 0;
  background-color: ${colors.gray_100};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 1.2rem;
  }
`;

interface RowInfoInterface {
  $isSuccess?: boolean;
  $noMargin?: boolean;
}
export const RowInfo = styled.div<RowInfoInterface>`
  width: 100%;
  display: flex;
  align-items: center;
  ${({ $noMargin }) => !$noMargin && "margin-bottom: 1.6rem;"}

  & > *:not(:first-child) {
    margin-left: 0.8rem;
  }

  & > svg rect {
    fill: ${({ $isSuccess }) =>
      $isSuccess ? colors.success_500 : colors.gray_300};
  }
`;

interface ContactInfoface {
  $isVerifyInfo?: boolean;
}
export const ContactInfo = styled.div<ContactInfoface>`
  width: 100%;
  display: flex;
  align-items: ${({ $isVerifyInfo }) =>
    $isVerifyInfo ? "flex-start" : "center"};
  justify-content: center;
  ${({ $isVerifyInfo }) => !$isVerifyInfo && "margin-bottom: 1.6rem;"}
  ${({ $isVerifyInfo }) => $isVerifyInfo && "flex-direction: column;"}
  ${({ $isVerifyInfo }) =>
    $isVerifyInfo
      ? `& > *:not(:first-child) {
    margin-top: 1.6rem;
  }`
      : `& > *:not(:first-child) {
    margin-left: 1.6rem;
  }`};
`;
