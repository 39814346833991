import { FC, ReactNode } from "react";
import { Button, ButtonProps } from "@mui/material";
import styled from "styled-components";
import { colors } from "helpers/consts";

export interface MainButtonProps extends ButtonProps {
  children?: ReactNode;
  maxWidth?: string;
  minWidth?: string;
  maxHeight?: string;
  fontSize?: number;
  lineHeight?: number;
  fullWidth?: boolean;
  toFillSvg?: boolean;
  toFillAndStrokeSvg?: boolean;
  darkTheme?: boolean;
}

const MainButton: FC<MainButtonProps> = ({
  children,
  maxWidth,
  minWidth,
  maxHeight,
  fontSize,
  fullWidth,
  toFillSvg,
  toFillAndStrokeSvg,
  lineHeight,
  darkTheme,
  ...props
}) => {
  return (
    <StyledMainButton
      $maxWidth={maxWidth}
      $minWidth={minWidth}
      $maxHeight={maxHeight}
      $fontSize={fontSize}
      $fullWidth={fullWidth}
      $toFillSvg={toFillSvg}
      $toFillAndStrokeSvg={toFillAndStrokeSvg}
      $lineHeight={lineHeight}
      $darkTheme={darkTheme}
      {...props}
    >
      {children}
    </StyledMainButton>
  );
};

export interface StyledMainButtonProps extends ButtonProps {
  $maxWidth?: string;
  $minWidth?: string;
  $maxHeight?: string;
  $fontSize?: number;
  $lineHeight?: number;
  $fullWidth?: boolean;
  $toFillSvg?: boolean;
  $toFillAndStrokeSvg?: boolean;
  $forTable?: boolean;
  $darkTheme?: boolean;
}
export const StyledMainButton = styled(Button)<StyledMainButtonProps>`
  padding: ${({ $forTable }) => ($forTable ? "1rem 0" : "1rem 1.6rem")};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  border-radius: 0.6rem;
  min-height: ${({ $maxHeight }) => ($maxHeight ? $maxHeight : "4.4rem")};
  max-height: ${({ $maxHeight }) => ($maxHeight ? $maxHeight : "4.4rem")};

  text-transform: none;
  font-weight: 600;
  font-size: ${({ $fontSize }) =>
    $fontSize ? `${$fontSize / 10}rem` : `1.4rem`};
    line-height: ${({ $lineHeight }) =>
      $lineHeight ? `${$lineHeight / 10}rem` : `1.2`};
  ${({ $fullWidth }) => $fullWidth && `width: 100%;`}
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`} 
  ${({ $forTable }) => $forTable && `text-decoration: underline;`} 
  
  & > svg,
  & > img {
    min-width: 2rem;
    max-width: 2rem;
    max-height: 2rem;
    min-height: 2rem;
    margin-right: 0.8rem;
  }
  & > svg path {
      transition: stroke 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  ${({
    variant,
    color,
    $toFillSvg,
    $toFillAndStrokeSvg,
    $forTable,
    $minWidth,
    $darkTheme,
  }) => {
    if (variant === "text") {
      return `color: ${colors.primary_600};
        min-width: ${$minWidth ? $minWidth : "2rem"};
        > svg path {
            ${
              $toFillAndStrokeSvg
                ? `fill: ${colors.primary_600};
                stroke: ${colors.primary_600};`
                : $toFillSvg
                ? `fill: ${colors.primary_600};`
                : `stroke: ${colors.primary_600};`
            }}
            ${
              !$forTable
                ? `&:hover,
               &:focus {
                 background-color: ${colors.primary_50};
                 > svg path {
              ${
                $toFillAndStrokeSvg
                  ? `fill: ${colors.primary_600};
                stroke: ${colors.primary_600};`
                  : $toFillSvg
                  ? `fill: ${colors.primary_600};`
                  : `stroke: ${colors.primary_600};`
              }
            }
          }`
                : `&:hover,
                   &:focus {
                     background-color: transparent;
                  }`
            }
            ${
              $forTable &&
              `&:hover,
                   &:focus {
                     color: ${colors.primary_500};
                }`
            }
        &:disabled,
        &.Mui-disabled {
          color: ${colors.gray_300};

          svg path {
            ${
              $toFillAndStrokeSvg
                ? `fill: ${colors.gray_300};
                stroke: ${colors.gray_300};`
                : $toFillSvg
                ? `fill: ${colors.gray_300};`
                : `stroke: ${colors.gray_300};`
            }
          }
        }
         `;
    } else if (variant === "contained") {
      return `
        background-color: ${colors.primary_500};
        border: 1px solid ${colors.primary_500};
        color: ${colors.white};
        box-shadow: none;
        min-width: ${$minWidth ? $minWidth : "6rem"};

        svg path {
          ${
            $toFillAndStrokeSvg
              ? `fill: ${colors.white};
                stroke: ${colors.white};`
              : $toFillSvg
              ? `fill: ${colors.white};`
              : `stroke: ${colors.white};`
          }
        }

        &:disabled {
          background-color: ${$darkTheme ? colors.gray_500 : colors.gray_100};
          border: 1px solid ${$darkTheme ? colors.gray_400 : colors.gray_300};
          color: ${colors.gray_300};

          svg path {
            ${
              $toFillAndStrokeSvg
                ? `fill: ${colors.gray_300};
                stroke: ${colors.gray_300};`
                : $toFillSvg
                ? `fill: ${colors.gray_300};`
                : `stroke: ${colors.gray_300};`
            }
          }
        }

        &:hover,
        &:focus {
          background-color: ${colors.primary_600};
          border: 1px solid ${colors.primary_600};
          box-shadow: none;
        }
      `;
    } else {
      return `
        min-width: ${$minWidth ? $minWidth : "6rem"};
        background-color: ${
          color === "secondary" ? colors.white : colors.primary_50
        };
        border: 1px solid ${
          color === "secondary" ? colors.gray_200 : colors.primary_200
        };
        color: ${color === "secondary" ? colors.gray_700 : colors.primary_600};

        svg path {
          ${
            $toFillAndStrokeSvg
              ? `fill: ${
                  color === "secondary" ? colors.gray_700 : colors.primary_600
                };
                stroke: ${
                  color === "secondary" ? colors.gray_700 : colors.primary_600
                };`
              : $toFillSvg
              ? `fill: ${
                  color === "secondary" ? colors.gray_700 : colors.primary_600
                };`
              : `stroke: ${
                  color === "secondary" ? colors.gray_700 : colors.primary_600
                };`
          }
        }

        &:disabled {
          background-color: ${colors.gray_100};
          border: 1px solid ${colors.gray_300};

          svg path {
            ${
              $toFillSvg
                ? `fill: ${colors.gray_300};`
                : `stroke: ${colors.gray_300};`
            }
          }
        }

        &:hover,
        &:focus {
            background-color: ${
              color === "secondary" ? colors.gray_200 : colors.primary_200
            };
        }
      `;
    }
  }}
`;

export default MainButton;
